import { DbConnectedSensor } from "../Devices/DbConnectedSensor"
import { WsPotlijnNotificationItem } from "./WsPotlijnNotificationItem"

export class WsPotlijnNotificationItems{
  ListItems!: WsPotlijnNotificationItem[]
}

export class Settings{
  moduleId!: string
  enableNotifications!: boolean
  enableSound!: boolean

  connectedSensors!: [DbConnectedSensor]
}