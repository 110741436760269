import { Button, Image } from 'antd';

import style from './RenderCardTitle.module.css';

import { EditOutlined } from '@ant-design/icons';

export function RenderCardTitle(
  key: string,
  title: string,
  icon: any,
  navigateTo: Function,
  edit: Function,
  argument?: any,
) {
  var classNameFlag = 'fi fi-' + key;

  return (
    <>
      <div className={style.titleRender}>
        <div style={{display:"flex", flexDirection: 'row'}}>
          <span className={classNameFlag}></span>
          <div style={{marginTop: 3, marginLeft: 3}}>{title}</div>
        </div>

        <div>
          <Button
            type="primary"
            shape="circle"
            icon={<EditOutlined />}
            onClick={() => edit(argument)}
            style={{ marginRight: '5px' }}
          ></Button>
          <Button
            type="primary"
            shape="circle"
            icon={icon}
            onClick={() => navigateTo(argument)}
          ></Button>
        </div>
      </div>
    </>
  );
}
