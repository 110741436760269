import { BehaviorSubject, Observable } from 'rxjs';
import { ModulePowerScheme } from '../Models/Devices/ModulePowerScheme';
import { ControlDevice } from '../Models/Devices/ControlDevice';
import { CounterDevice } from '../Models/Devices/CounterDevice';
import { NotificationDevice } from '../Models/Devices/NotificationDevice';
import { NotificationDevicesHelper } from '../Helpers/NotificationDevicesHelper';
import { CounterDevicesHelper } from '../Helpers/CounterDevicesHelper';
import { ControlDevicesHelper } from '../Helpers/ControlDevicesHelper';
import { ModulePowerSchemeHelper } from '../Helpers/ModulePowerSchemeHelper';

export class DevicesService {
  public FetchingReadySubject: BehaviorSubject<boolean>;
  public DeviceIsEdit: BehaviorSubject<boolean>;
  private WifiCheckReady: boolean = false;
  public FetchingReady!: boolean;

  public controlDevices!: Array<ControlDevice>;
  public notificationDevices!: Array<NotificationDevice>;
  public counterDevices!: Array<CounterDevice>;
  public powerSchemes!: Array<ModulePowerScheme>;
  constructor() {
    this.FetchingReadySubject = new BehaviorSubject<boolean>(false);
    this.DeviceIsEdit = new BehaviorSubject<boolean>(false);
  }

  private async FetchItems() {
    if (!this.FetchingReady) {
      this.FetchingReady = true;

      this.controlDevices = await ControlDevicesHelper.GetDevices();
      this.notificationDevices = await NotificationDevicesHelper.GetDevices();
      this.counterDevices = await CounterDevicesHelper.GetDevices();
      this.powerSchemes = await ModulePowerSchemeHelper.GetModules();

      this.FetchingReadySubject.next(true);
    }
  }

  public GetFetchStatus(): Observable<boolean> {
    this.FetchItems();
    return this.FetchingReadySubject;
  }

  public SetWifiStatus() {
    this.WifiCheckReady = true;
  }

  public GetWifiStatus() {
    return this.WifiCheckReady;
  }

  public GetNotificationDevices() {
    return this.notificationDevices;
  }

  public GetCounterDevices() {
    return this.counterDevices;
  }

  public GetControlDevices() {
    return this.controlDevices;
  }

  public GetPowerSchemeModule() {
    return this.powerSchemes;
  }

  public async UpdateAndSafeNotificationDevice(newItem: NotificationDevice) {
    var index = this.notificationDevices.findIndex((x) => x.id == newItem.id);
    if (newItem.id != '') {
      NotificationDevicesHelper.UpdateDevice(newItem);
      this.notificationDevices[index] = newItem;
      return newItem;
    } else {
      var newDevice = await NotificationDevicesHelper.AddDevice(newItem);
      this.notificationDevices[index] = newDevice;
      return newDevice;
    }
  }

  public async UpdateAndSafeCounterDevice(newItem: CounterDevice) {
    var index = this.counterDevices.findIndex((x) => x.id == newItem.id);
    if (newItem.id != '') {
      CounterDevicesHelper.UpdateDevice(newItem);
      this.counterDevices[index] = newItem;
      return newItem;
    } else {
      var newDevice = await CounterDevicesHelper.AddDevice(newItem);
      this.counterDevices[index] = newDevice;
      return newDevice;
    }
  }

  public async UpdateAndSafeControlDevice(newItem: ControlDevice) {
    var index = this.controlDevices.findIndex((x) => x.id == newItem.id);
    if (newItem.id != '') {
      ControlDevicesHelper.UpdateDevice(newItem);
      this.controlDevices[index] = newItem;
      return newItem;
    } else {
      var newDevice = await ControlDevicesHelper.AddDevice(newItem);
      this.controlDevices[index] = newDevice;
      return newDevice;
    }
  }

  // update device
  public UpdateNotificationDevice(item: NotificationDevice) {
    var device = this.notificationDevices.find(
      (x) => x.moduleId == item.moduleId,
    );
    device = item;
    return this.notificationDevices;
  }

  public UpdateCounterDevice(item: CounterDevice) {
    var device = this.counterDevices.find((x) => x.moduleId == item.moduleId);
    device = item;
    return this.counterDevices;
  }

  public UpdateControlDevice(item: ControlDevice) {
    var device = this.controlDevices.find((x) => x.moduleId == item.moduleId);
    device = item;
    return this.controlDevices;
  }

  public UpdatePowerSchemeModule(newPowerScheme: ModulePowerScheme) {
    var index = this.powerSchemes.findIndex(
      (x) => x.moduleId == newPowerScheme.id,
    );
    this.powerSchemes[index] = newPowerScheme;
  }

  // add device
  public async AddNotificationDevice(item: NotificationDevice) {
    this.notificationDevices.push(item);
  }
  public AddCounterDevice(newCounterDevice: CounterDevice) {
    this.counterDevices.push(newCounterDevice);
  }
  public AddControlDevice(newControlDevice: ControlDevice) {
    this.controlDevices.push(newControlDevice);
  }
  public AddPowerSchemeModule(newPowerScheme: ModulePowerScheme) {
    this.powerSchemes.push(newPowerScheme);
  }

  // remove device
  public RemoveNotificationDevice(item: NotificationDevice) {
    var index = this.notificationDevices.findIndex((x) => x.id == item.id);
    if (index >= 0) {
      this.notificationDevices.splice(index, 1);
    }

    if (item.id != '') {
      NotificationDevicesHelper.DeleteDevice(item);
    }
  }

  public RemoveCounterDevice(item: CounterDevice) {
    var index = this.counterDevices.findIndex((x) => x.id == item.id);
    if (index >= 0) {
      this.counterDevices.splice(index, 1);
    }

    if (item.id != '') {
      CounterDevicesHelper.DeleteDevice(item);
    }
  }

  public RemoveControlDevice(item: ControlDevice) {
    var index = this.controlDevices.findIndex((x) => x.id == item.id);
    if (index >= 0) {
      this.controlDevices.splice(index, 1);
    }

    if (item.id != '') {
      ControlDevicesHelper.DeleteDevice(item);
    }
  }
  public RemovePowerSchemeModule(index: number) {
    this.powerSchemes.splice(index, 1);
  }

  public ReplaceNotificationDevices(items: NotificationDevice[]) {
    this.notificationDevices = items;
    NotificationDevicesHelper.ReplaceDevices(items);
    return this.notificationDevices;
  }

  public ReplaceCounterDevices(items: CounterDevice[]) {
    this.counterDevices = items;
    CounterDevicesHelper.ReplaceDevices(items);
    return this.counterDevices;
  }

  public ReplaceControlDevices(items: ControlDevice[]) {
    this.controlDevices = items;
    ControlDevicesHelper.ReplaceDevices(items);
    return this.controlDevices;
  }
}
