import { authService } from "../App";
import { NotificationDevice } from "../Models/Devices/NotificationDevice";
import { HttpHelper } from "./HttpHelper";

export abstract class NotificationDevicesHelper{
    private static baseUrl = HttpHelper.GetUrlBaseApi() + "api/" + "NotificationDevice"

    public static async GetDevices(){
        var urlForRequest = this.baseUrl;
        return (await HttpHelper.GetRequestForApi<NotificationDevice[]>(urlForRequest));
    }

    public static async ReplaceDevices(device: NotificationDevice[]){
        var token = authService.GetToken();
        var urlForRequest = this.baseUrl + "/" + "ReplaceAll";
        return (await HttpHelper.PostRequestForApi<NotificationDevice>(urlForRequest, device, token));
    }

    public static async UpdateDevice(device: NotificationDevice){
        var token = authService.GetToken();
        var urlForRequest = this.baseUrl + "/" + device.id;
        return (await HttpHelper.UpdateRequestForApi<NotificationDevice>(urlForRequest, device, token));
    }

    public static async AddDevice(device: NotificationDevice){
        var token = authService.GetToken();
        var urlForRequest = this.baseUrl
        return (await HttpHelper.PostRequestForApi<NotificationDevice>(urlForRequest, device, token))
    }

    public static async DeleteDevice(device: NotificationDevice){
        var token = authService.GetToken();
        var urlForRequest = this.baseUrl + "/" + device.id
        return (await HttpHelper.DeleteRequestForApi<NotificationDevice>(urlForRequest, device, token))
    }
}