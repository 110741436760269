import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { animated, useTransition } from '@react-spring/web';
import { NotificationModule } from '../../Models/Notifications/NotificationMachine';
import { useAutoAnimate } from '@formkit/auto-animate/react';

import style from './CustomAnimatedList.module.css';
import './../../Styles/notification-lines-colors.css';

import Typography from 'antd/es/typography/Typography';
import { HttpHelper } from '../../Helpers/HttpHelper';

export const MyList = forwardRef((props: {}, ref) => {
  const [notificationItems, setNotificationItems] = useState<
    NotificationModule[]
  >([]);

  const [useAnimation, setUseAnimation] = useState<boolean>(true);
  const [animationParent] = useAutoAnimate();

  useImperativeHandle(ref, () => ({
    AddNotifications(items: NotificationModule[]) {
      var oldNotificationItems = [...notificationItems];
      var CheckUseAnimation: Boolean = true;
      items.forEach((item) => {
        var oldIndex = oldNotificationItems.findIndex(
          (x) => x.sensorId == item.sensorId,
        );
        if (oldIndex >= 0) {
          oldNotificationItems[oldIndex] = item;
          CheckUseAnimation = false;
        } else {
          oldNotificationItems.push(item);
        }
      });
      setNotificationItems(oldNotificationItems);
    },

    RemoveNotifications(items: NotificationModule[]) {
      var oldNotificationItems = [...notificationItems];

      items.forEach((item) => {
        var indexToKill = oldNotificationItems.findIndex(
          (x) => x.sensorId == item.sensorId,
        );
        oldNotificationItems.splice(indexToKill, 1);
      });

      setNotificationItems(oldNotificationItems);
    },

    RemoveAllNotifications(items: NotificationModule[]) {
      setNotificationItems([]);
    },
  }));

  useEffect(() => {}, [0]);

  const GetLinkForImage = (item: NotificationModule): string => {
    return HttpHelper.GetUrlStorageBaseApi() + item.SymbolForUrl;
  };

  return (
    <React.Fragment>
      <div ref={animationParent}>
        {notificationItems.map((item) => (
          <div className={`${style.notificationBlock} ${item.color}`}>
            <div className={style.image}>
              <img className={style.imgSize} src={GetLinkForImage(item)} />{' '}
            </div>
            <div className={style.moduleName}>
              <Typography
                className={`${style.moduleNameStyle} ${'Text' + item.color}`}
              >
                {item.moduleName}
              </Typography>
            </div>
            <div className={style.moduleMessage}>
              <Typography
                className={`${style.moduleNameStyle} ${'Text' + item.color}`}
              >
                {item.message}
              </Typography>
            </div>
          </div>
        ))}
        ,
      </div>
    </React.Fragment>
  );
});
