import { AxiosResponse } from "axios"
import { UserType } from "../Enums/UserType"
import { UserModel } from "../Models/Auth/UserModel"
import { SoftwareSetting } from "../Models/SoftwareManager/SoftwareSettings"
import { HttpHelper } from "./HttpHelper"

export abstract class UserHelper{
    private static baseUrl = HttpHelper.GetUrlBaseApiHome() + "api/" + "User"
    
    public static async GetUsers(token: string){
        var urlForRequest = this.baseUrl
        return (await HttpHelper.GetRequestForApi<UserModel[]>(urlForRequest, token))
    }

    public static async AddUser(item: UserModel, token: string){
        var urlForRequest = this.baseUrl
        return (await HttpHelper.PostRequestForApi<UserModel>(urlForRequest, item, token))
    }

    public static async ConfigUser(item: UserModel, token: string){
        var urlForRequest = this.baseUrl + "/SetPassword"
        return (await HttpHelper.PostRequestForApi<UserModel>(urlForRequest, item, token))
    }

    public static async UpdateUser(item: UserModel, token: string){
        var urlForRequest = this.baseUrl + "/" + item.id
        return (await HttpHelper.UpdateRequestForApi<UserModel>(urlForRequest, item, token))
    }

    public static async DeleteUser(item: UserModel, token: string){
        var urlForRequest = this.baseUrl + "/" + item.id
        return (await HttpHelper.DeleteRequestForApi(urlForRequest, item, token))
    }
}