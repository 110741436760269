import {
  Avatar,
  Button,
  Dropdown,
  MenuProps,
  Space,
} from 'antd';
import {
  InfoCircleOutlined,
  FlagOutlined,
  UserOutlined,
  HomeOutlined,
  TabletOutlined,
  DotChartOutlined,
  SettingOutlined
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const handleMenuClick: MenuProps['onClick'] = (e) => {};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const AvatarDropdown = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );
  const [sizeAvatar, setSizeAvatar] = useState(32);
  const { i18n, t } = useTranslation();

  useEffect(() => {
    ResizeAvatar();

    function handleResize() {
      ResizeAvatar();
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const GetMenuOptions = () => {
    const items: MenuProps['items'] = [
      {
        key: '1',
        icon: <HomeOutlined />,
        label: <Link to="/">{t('home')}</Link>,
      },
      {
        key: '2',
        icon: <UserOutlined />,
        label: <Link to="/profile">{t('profile')}</Link>,
      },
      {
        label: <Link to="/devices">{t('devices')}</Link>,
        key: '3',
        icon: <TabletOutlined />,
      },
      {
        label: <Link to="/production">{t('production')}</Link>,
        key: '4',
        icon: <DotChartOutlined />
      },
      {
        label: <Link to="/settings">{t('settings')}</Link>,
        key: '5',
        icon: <SettingOutlined />
      },
      {
        label: <Link to="/languages">{t('translations')}</Link>,
        key: '6',
        icon: <FlagOutlined />,
      },
      {
        label: <Link to="/info">{t('info')}</Link>,
        key: '7',
        icon: <InfoCircleOutlined />,
      },
    ];

    return items;
  };

  const menuProps = {
    items: GetMenuOptions(),
    onClick: handleMenuClick,
  };

  const ResizeAvatar = () => {
    setWindowDimensions(getWindowDimensions());

    if (windowDimensions.width > 2000) {
      setSizeAvatar(64);
    } else {
      setSizeAvatar(32);
    }
  };

  return (
    <Space wrap>
      <Dropdown menu={menuProps}>
        <Button
          style={{ borderStyle: 'none', background: 'rgba(0,0,0,0.0)', top: 2 }}
        >
          <Avatar
            size={sizeAvatar}
            style={{ backgroundColor: '#C1D202' }}
            icon={<UserOutlined />}
          />
        </Button>
      </Dropdown>
    </Space>
  );
};

export default AvatarDropdown;
