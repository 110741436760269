import { BehaviorSubject, map, Observable, Observer, Subject } from 'rxjs';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { IMessageSock } from '../Models/WebSocket/IMessageSock';
import { LocalHelper } from '../Helpers/LocalHelper';
import { HttpHelper } from '../Helpers/HttpHelper';

class WebSocketService {
  private subject?: AnonymousSubject<MessageEvent>;
  public messages: Subject<IMessageSock>;
  public messagesChannel: BehaviorSubject<any | null>;
  private ws: any;

  constructor() {
    var endpointWebsocket = '';

    this.messagesChannel = new BehaviorSubject<IMessageSock | null>(null);

    endpointWebsocket = this.GetEndpoint()

    this.messages = <Subject<IMessageSock>>this.connect(endpointWebsocket).pipe(
      map((response: MessageEvent) => {
        let data = JSON.parse(response.data);
        this.messagesChannel.next(data);
        return data;
      }),
    );

    this.messages.subscribe((x) => {
        this.messagesChannel.next(x);
    });
  }

  public GetEndpoint(): string {
    if (HttpHelper.IsRemoteHosted()) {
      return  LocalHelper.GetUrlForSocketRemote();
    } else {
      return LocalHelper.GetUrlForSocketLocal();
    }
    return LocalHelper.GetUrlForSocketLocalHost()
  }

  public connect(url: string): AnonymousSubject<MessageEvent> {
    if (!this.subject) {
      this.subject = this.create(url);
    }
    return this.subject;
  }

  private create(url: string): AnonymousSubject<MessageEvent> {
    this.ws = new WebSocket(url);
    let observable = new Observable((obs: Observer<MessageEvent>) => {
      this.ws.onmessage = obs.next.bind(obs);
      this.ws.onerror = obs.error.bind(obs);
      this.ws.onclose = obs.complete.bind(obs);
      return this.ws.close.bind(this.ws);
    });

    let observer = {
      error: () => {},
      complete: () => {},
      next: (data: Object) => {},
    };
    return new AnonymousSubject<MessageEvent>(observer, observable);
  }

  public async sendMessage(message: any) {
    await this.ws.send(JSON.stringify(message));
  }

  public close() {
    this.ws.close();
  }
}

export { WebSocketService };
