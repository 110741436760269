import react, { useEffect, useRef, useState } from 'react';

import style from './EditCounterDeviceLay.module.css';
import { Button, Divider, Form, Input, Popconfirm, Select, Switch } from 'antd';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { DbConnectedSensor } from '../../../Models/Devices/DbConnectedSensor';
import { serviceBundler, webSocketService } from '../../../App';

import { CounterDevice } from '../../../Models/Devices/CounterDevice';
import { TypeModule } from '../../../Models/Devices/TypeModule';
import { WsDeviceUpdate } from '../../../Models/WebSocket/WsRefreshData';

const EditCounterDeviceLay = (props: {
  allData: CounterDevice[];
  data: CounterDevice;
  onRemove: Function;
  onSafe: Function;
  onSafeWithoutClose: Function;
  onCancel: Function;
}) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();

  const [currentDevice, setCurrentDevice] = useState<CounterDevice>();
  useState<DbConnectedSensor | null>();

  useEffect(() => {
    form.resetFields();
    setCurrentDevice(props.data);
  }, [form, props.data, currentDevice]);

  const CancelEdit = () => {
    props.onCancel(currentDevice);
  };

  const checkLabelExist = (_: any, value: string) => {
    var foundItem = props.allData?.find((x) => x.moduleName === value);
    if (foundItem !== undefined) {
      if (foundItem.id === props.data?.id) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Deze email bestaat al'));
    }
    return Promise.resolve();
  };

  const onFinishForm = (item: CounterDevice) => {
    SafeDeviceAfterUpdate(item);
  };
  const onFinishFormFailed = (errorInfo: any) => {};

  const RemoveDevice = async () => {
    if (currentDevice) {
      serviceBundler.devicesService.RemoveCounterDevice(currentDevice);
      props.onRemove(currentDevice);
    }
  };

  const SafeDevice = async () => {
    form.submit()
  };

  const SafeDeviceAfterUpdate = async (item: CounterDevice) => {
    if (currentDevice) {
      item.id = currentDevice.id;
      var device =
        await serviceBundler.devicesService.UpdateAndSafeCounterDevice(
          item,
        );
      props.onSafe(item);
      webSocketService.sendMessage(new WsDeviceUpdate())
    }
  };

  const CancelOperation = () => {
    if (currentDevice) {
      if (currentDevice.id == '') {
        serviceBundler.devicesService.RemoveCounterDevice(currentDevice);
      }
    }

    props.onCancel(currentDevice);
  };

  return (
    <>
      <div className={style.bodyLay}>
        <div className={style.bodyTextBadge}>
          <Button icon={<ArrowLeftOutlined />} onClick={CancelEdit}></Button>
        </div>
        <div className={style.paneEdit}>
          <div>
            <Divider>{t('counterdevice')}</Divider>
          </div>

          <div style={{ display: 'flex', justifyContent: 'left'}}>
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ width: 500 }}
              initialValues={currentDevice}
              onFinish={onFinishForm}
              onFinishFailed={onFinishFormFailed}
              autoComplete="off"
              form={form}
            >
              <Form.Item
                label={t('modulename')}
                name="moduleName"
                key="moduleName"
                required
                rules={[
                  {
                    required: true,
                    message: 'Email is vereist',
                  },
                  { validator: checkLabelExist },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('moduleid')}
                name="moduleId"
                key="moduleId"
                required
                rules={[
                  {
                    required: true,
                    message: 'Email is vereist',
                  },
                  { validator: checkLabelExist },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('enablecount')}
                name="enableCount"
                key="enableCount"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
              <Form.Item
                label={t('countersensorid')}
                name="counterSensorId"
                key="counterSensorId"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('presssensorid')}
                name="pressSensorId"
                key="pressSensorId"
              >
                <Input />
              </Form.Item>
            </Form>
          </div>

          <div className={style.btnGroup}>
            <Button onClick={CancelOperation} type="primary">
              {t('cancel')}
            </Button>
            <Popconfirm
              title={t('removedevice')}
              description={t('areyousure')}
              onConfirm={RemoveDevice}
              okText={t('yes')}
              cancelText={t('no')}
            >
              <Button type="primary">{t('remove')}</Button>{' '}
            </Popconfirm>

            <Button onClick={SafeDevice} type="primary">
              {t('safe')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCounterDeviceLay;
