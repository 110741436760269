import { BehaviorSubject, Observable } from 'rxjs';
import { SettingsWebportal } from '../Models/Settings/SettingsWebportal';
import { SettingsWebportalHelper } from '../Helpers/SettingsWebportalHelper';
import { SoundData } from '../Models/Settings/SoundData';

export class SettingsWebportalService {
  public FetchingReadySubject: BehaviorSubject<boolean>;
  public FetchingReady!: boolean;

  public setting!: SettingsWebportal;
  constructor() {
    this.FetchingReadySubject = new BehaviorSubject<boolean>(false);

    this.FetchItems();
  }

  public GetFetchStatus(): Observable<boolean> {
    this.FetchItems();
    return this.FetchingReadySubject;
  }

  private async FetchItems() {
    if (!this.FetchingReady) {
      this.FetchingReady = true;

      this.setting = await SettingsWebportalHelper.GetSetting();
      this.FetchingReadySubject.next(true);
    }
  }

  public GetSetting() {
    return this.setting;
  }

  public async GetSettingUpdated() {
    this.setting = await SettingsWebportalHelper.GetSetting();
    return this.setting
  }

  public SafeSetting(item: SettingsWebportal){
    SettingsWebportalHelper.UpdateSetting(item)
  }

  public RemoveSound(item: SoundData){
    SettingsWebportalHelper.RemoveSound(item)
  }
}
