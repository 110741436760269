import dayjs, { Dayjs } from "dayjs";



export class ProductionOverview {
  id!: string;
  typeName: string = '';
  timeStampStart!: string;
  timeStampStop?: string;
  quantityTrays: number = 0;

  constructor(init?: Partial<ProductionOverview>) {}
}
