export enum ColorType{
    DarkRed,
    Red,
    Orange,
    Yellow,
    LightGreen,
    DarkGreen,
    LightBlue,
    Blue,
    DarkBlue,
    Purple,
}