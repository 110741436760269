import { Button, Form, Modal } from 'antd';
import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import style from './AddSpecialFunction.module.css';

import {
  LoadingOutlined,
  PlusOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

import type { UploadChangeParam } from 'antd/es/upload';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { ResultAddDevice } from '../../../../Enums/ResultAddDevice';
import { ResultAddFunction } from '../../../../Enums/ResultAddFunction';
import { DbConnectedSensor } from '../../../../Models/Devices/DbConnectedSensor';

export function AddSpecialFunction(props: {
  open: boolean;
  sensor?: DbConnectedSensor;
  onCancel: Function;
  onChoose: Function;
}) {
  const [form] = Form.useForm();

  const { i18n, t } = useTranslation();

  const handleCancel = () => {
    props.onCancel();
  };

  const ChooseFunction = (result: ResultAddFunction) => {
    props.onChoose(result);
  };

  return (
    <Modal
      title={t('addspecialfucntion')}
      open={props.open}
      onOk={() => form.submit()}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          {t('cancel')}
        </Button>,
      ]}
    >
      <div className={style.buttonRow}>
      {!props.sensor?.trayCounter && (
          <Button
            icon={<PlusOutlined />}
            onClick={() =>
              ChooseFunction(ResultAddFunction.AddTrayCounter)
            }
          >
            {t('traycounter')}
          </Button>
        )}
        {!props.sensor?.externalPowerState && (
          <Button
            icon={<PlusOutlined />}
            onClick={() =>
              ChooseFunction(ResultAddFunction.AddExternalPowerState)
            }
          >
            {t('externalpowerstate')}
          </Button>
        )}
        {!props.sensor?.externalPowerStateLinked && (
          <Button
            icon={<PlusOutlined />}
            onClick={() =>
              ChooseFunction(ResultAddFunction.AddExternalPowerStateLinked)
            }
          >
            {t('externalpowerstatelinked')}
          </Button>
        )}
        {!props.sensor?.externalShutdownSignal && (
          <Button
            icon={<PlusOutlined />}
            onClick={() =>
              ChooseFunction(ResultAddFunction.AddExternalShutdownSignal)
            }
          >
            {t('externalshutdownsignal')}
          </Button>
        )}
      </div>
    </Modal>
  );
}
