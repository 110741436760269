import { ModulePowerScheme } from "../Models/Devices/ModulePowerScheme";
import { HttpHelper } from "./HttpHelper";

export abstract class ModulePowerSchemeHelper {
  private static baseUrl =
    HttpHelper.GetUrlBaseApi() + 'api/' + 'ModulePowerScheme';
    
  public static async GetModules(): Promise<ModulePowerScheme[]> {
    var urlForRequest = this.baseUrl;
    return await HttpHelper.GetRequestForApi<ModulePowerScheme[]>(urlForRequest);
  }

  public static async UpdateSetting(setting: ModulePowerScheme) {
    var urlForRequest = this.baseUrl + '/' + setting.id;
    return await HttpHelper.UpdateRequestForApi<ModulePowerScheme>(
      urlForRequest,
      setting
    );
  }

  public static async AddSetting(setting: ModulePowerScheme) {
    var urlForRequest = this.baseUrl;
    return await HttpHelper.PostRequestForApi<ModulePowerScheme>(
      urlForRequest,
      setting
    );
  }
}
