import react, { useEffect, useRef, useState } from 'react';

import style from './EditNotificationDeviceLay.module.css';
import { NotificationDevice } from '../../../Models/Devices/NotificationDevice';
import { Button, Card, Divider, Popconfirm } from 'antd';

import {
  ArrowLeftOutlined,
  TableOutlined,
  PartitionOutlined,
  PlusSquareOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { DbConnectedSensor } from '../../../Models/Devices/DbConnectedSensor';
import { EditNotificationGlobal } from '../Forms/EditNotificationGlobal/EditNotificationGlobal';
import { EditNotificationSensor } from '../Forms/EditNotificationSensor/EditNotificationSensor';
import { serviceBundler, webSocketService } from '../../../App';

import { v4 } from 'uuid';
import { WsDeviceUpdate } from '../../../Models/WebSocket/WsRefreshData';

const EditNotificationDeviceLay = (props: {
  allData: NotificationDevice[];
  data: NotificationDevice;
  onRemove: Function;
  onSafe: Function;
  onSafeWithoutClose: Function;
  onCancel: Function;
}) => {
  const { t, i18n } = useTranslation();

  const [currentDevice, setCurrentDevice] = useState<NotificationDevice>();
  const [showGeneral, setShowGeneral] = useState<boolean>(true);
  const [currentSensor, setCurrentSensor] =
    useState<DbConnectedSensor | null>();

  const [safeDevice, setSafeDevice] = useState<boolean>(false);
  const [validationGlobal, setValidationGlobal] = useState<boolean>(false);
  const [validationSensor, setValidationSensor] = useState<boolean>(false);

  const globalRef = useRef<any>();
  const sensorRef = useRef<any>();

  useEffect(() => {
    setCurrentDevice(props.data);
  }, [props.data]);

  useEffect(() => {
    if (!validationGlobal) {
      return;
    }

    if (!validationSensor && currentSensor != null) {
      return;
    }

    if (safeDevice) {
      SafeDeviceAfterUpdate();
    }
  }, [validationGlobal, validationSensor, safeDevice]);

  const CancelEdit = () => {
    props.onCancel(currentDevice);
  };

  const ShowGeneralData = () => {
    SafeOldSensorData();

    setCurrentSensor(null);
    setShowGeneral(true);
  };

  const ShowSpecificSensorData = (item: DbConnectedSensor) => {
    SafeOldGlobalData();
    SafeOldSensorData();

    setShowGeneral(false);
    setCurrentSensor(item);
  };

  const SafeOldGlobalData = () => {
    if (currentDevice) {
      const node = globalRef.current;
      if (node) {
        node.SafeGlobal();
      }
    }
  };

  const SafeOldSensorData = () => {
    if (currentSensor) {
      const node = sensorRef.current;
      if (node) {
        node.SafeSensor();
      }
    }
  };

  const SafeGlobalCallBack = (item: NotificationDevice | null) => {
    if (item == null) {
      setValidationGlobal(false);
      return;
    }
    if (!currentDevice) {
      return;
    }

    setCurrentDevice(item);
    setShowGeneral(false);
    setValidationGlobal(true);
  };

  const SafeSensorCallBack = (item: DbConnectedSensor) => {
    if (item == null) {
      setValidationSensor(false);
      return;
    }
    if (currentDevice == undefined) {
      return;
    }

    var indexSensor = currentDevice.connectedSensors.findIndex(
      (x) => x.id == item.id,
    );

    if (indexSensor >= 0) {
      currentDevice.connectedSensors[indexSensor] = item;
    } else {
      currentDevice.connectedSensors.push(item);
    }

    setCurrentDevice(currentDevice);
    setValidationSensor(true);
  };

  const RemoveSensor = async (item: DbConnectedSensor) => {
    if (!currentDevice) {
      return;
    }

    var indexSensor = currentDevice?.connectedSensors.findIndex(
      (x) => x.id == item.id,
    );

    if (indexSensor >= 0) {
      currentDevice.connectedSensors.splice(indexSensor, 1);
      setCurrentDevice(currentDevice);
      setCurrentSensor(null);
      setShowGeneral(true);
    }

    var device =
      await serviceBundler.devicesService.UpdateAndSafeNotificationDevice(
        currentDevice,
      );
    props.onSafeWithoutClose(currentDevice);
  };

  const AddSensor = () => {
    SafeOldGlobalData()
    if (currentDevice) {
      var newSensor = new DbConnectedSensor();
      newSensor.id = v4();
      setCurrentSensor(newSensor);
      setShowGeneral(false);
    }
  };

  const RemoveDevice = async () => {
    if (currentDevice) {
      serviceBundler.devicesService.RemoveNotificationDevice(currentDevice);
      props.onRemove(currentDevice);
    }
  };

  const SafeDevice = async () => {
    SafeOldSensorData();
    SafeOldGlobalData();
    setSafeDevice(true);
  };

  const SafeDeviceAfterUpdate = async () => {
    if (currentDevice) {
      var device =
        await serviceBundler.devicesService.UpdateAndSafeNotificationDevice(
          currentDevice,
        );
      props.onSafe(currentDevice);
      setSafeDevice(false);
      setValidationGlobal(false);
      setValidationSensor(false);

      webSocketService.sendMessage(new WsDeviceUpdate())
    }
  };

  const CancelOperation = () => {
    if (currentDevice) {
      if (currentDevice.id == '') {
        serviceBundler.devicesService.RemoveNotificationDevice(currentDevice);
      }
    }

    props.onCancel(currentDevice);
  };

  return (
    <>
      <div className={style.bodyLay}>
        <div className={style.bodyTextBadge}>
          <Button icon={<ArrowLeftOutlined />} onClick={CancelEdit}></Button>
        </div>
        <div className={style.paneEdit}>
          <div className={style.paneRow}>
            <div className={style.itemsModule}>
              <Divider>{t('sensors')}</Divider>
              <Button
                onClick={() => ShowGeneralData()}
                icon={<TableOutlined />}
                style={{
                  textAlign: 'start',
                  height: '50px',
                  borderColor: showGeneral ? '#C1D202' : '',
                  color: showGeneral ? '#C1D202' : '',
                }}
                type="default"
                block
              >
                {t('general')}
              </Button>
              <div className={style.groupSensors}>
                {currentDevice?.connectedSensors.map(
                  (item: DbConnectedSensor, i) => (
                    <Button
                      icon={<PartitionOutlined />}
                      style={{
                        borderColor:
                          item.id == currentSensor?.id
                            ? '#C1D202'
                            : '',
                        color:
                          item.id == currentSensor?.id
                            ? '#C1D202'
                            : '',
                        marginLeft: 0,
                      }}
                      className={style.btnSensor}
                      onClick={() => ShowSpecificSensorData(item)}
                    >
                      {item.sensorName}
                    </Button>
                  ),
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginRight: 3,
                  marginTop: 10,
                }}
              >
                <Button onClick={AddSensor} icon={<PlusSquareOutlined />}>
                  {t('addsensor')}
                </Button>
              </div>
            </div>
            <div className={style.propsModule}>
              {showGeneral && (
                <EditNotificationGlobal
                  ref={globalRef}
                  allData={props.allData}
                  data={currentDevice}
                  onCancel={() => 1 == 1}
                  onSafe={SafeGlobalCallBack}
                ></EditNotificationGlobal>
              )}

              {currentSensor != null && (
                <EditNotificationSensor
                  ref={sensorRef}
                  allData={currentDevice?.connectedSensors}
                  data={currentSensor}
                  onCancel={() => 1 == 1}
                  onRemove={RemoveSensor}
                  onSafe={SafeSensorCallBack}
                ></EditNotificationSensor>
              )}
            </div>
          </div>
          <div className={style.btnGroup}>
            <Button onClick={CancelOperation} type="primary">
              {t('cancel')}
            </Button>
            <Popconfirm
              title={t('removedevice')}
              description={t('areyousure')}
              onConfirm={RemoveDevice}
              okText={t('yes')}
              cancelText={t('no')}
            >
              <Button type="primary">{t('remove')}</Button>{' '}
            </Popconfirm>

            <Button onClick={SafeDevice} type="primary">
              {t('safe')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditNotificationDeviceLay;
