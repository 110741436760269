import { SettingsWebportal } from '../Models/Settings/SettingsWebportal';
import { SoundData } from '../Models/Settings/SoundData';
import { HttpHelper } from './HttpHelper';

export abstract class SettingsWebportalHelper {
  private static baseUrl =
    HttpHelper.GetUrlBaseApi() + 'api/' + 'SettingsWebportal';

  public static GetUploadUrl() {
    return this.baseUrl + '/UploadSound';
  }

  public static async GetSetting() {
    var urlForRequest = this.baseUrl;
    return await HttpHelper.GetRequestForApi<SettingsWebportal>(urlForRequest);
  }

  public static async UpdateSetting(setting: SettingsWebportal) {
    var urlForRequest = this.baseUrl + '/' + setting.id;
    return await HttpHelper.UpdateRequestForApi<SettingsWebportal>(
      urlForRequest,
      setting,
    );
  }

  public static async RemoveSound(setting: SoundData) {
    var urlForRequest = this.baseUrl + '/RemoveSound/' + setting.id;
    return await HttpHelper.DeleteRequestForApi<SoundData>(
      urlForRequest,
      setting,
    );
  }
}
