import { ProductionOverview } from "../Models/Production/ProductionOverview"
import { HttpHelper } from "./HttpHelper"

export abstract class ProductionOverviewHelper{
    private static baseUrl = HttpHelper.GetUrlBaseApi() + "api/" + "ProductionOverview"
    public static async GetOverview(){
        var urlForRequest = this.baseUrl
        return (await HttpHelper.GetRequestForApi<ProductionOverview[]>(urlForRequest))
    }

    public static async UpdateOverview(overview: ProductionOverview){
        var urlForRequest = this.baseUrl + "/" + overview.id
        return (await HttpHelper.UpdateRequestForApi<ProductionOverview>(urlForRequest, overview))
    }

    public static async AddOverview(overview: ProductionOverview){
        var urlForRequest = this.baseUrl
        return (await HttpHelper.PostRequestForApi<ProductionOverview>(urlForRequest, overview))
    }

    public static async DeleteOverview(overview: ProductionOverview){
        var urlForRequest = this.baseUrl + "/" + overview.id
        return (await HttpHelper.DeleteRequestForApi<ProductionOverview>(urlForRequest, overview))
    }
}