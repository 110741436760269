export class ConnectedSwitch{
  id!: string
  switchId!: string
  switchName!: string
  visible: boolean = false

  currentPowerState: boolean = false;
  hourToStart: number = 0
  hourToStop: number = 23

  public constructor(init?:Partial<ConnectedSwitch>) {
    Object.assign(this, init);
  }
}

