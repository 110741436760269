import {
  Button,
  Form,
  Modal,
} from 'antd';
import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import style from './AddDevice.module.css';

import {
  PlusOutlined,
} from '@ant-design/icons';

import type { UploadChangeParam } from 'antd/es/upload';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { ResultAddDevice } from '../../../../Enums/ResultAddDevice';

export function AddDevice(props: {
  open: boolean;
  onCancel: Function;
  onChoose: Function;
}) {
  const [form] = Form.useForm();

  const { i18n, t } = useTranslation();

  useEffect(() => {}, [0]);

  const handleCancel = () => {
    props.onCancel();
  };

  const ChooseDevice = (result: ResultAddDevice) => {
    props.onChoose(result);
  };

  return (
    <Modal
      title={t('addadevice')}
      open={props.open}
      onOk={() => form.submit()}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          {t('cancel')}
        </Button>,
      ]}
    >
      <div className={style.buttonRow}>
        <Button icon={<PlusOutlined/>} onClick={() => ChooseDevice(ResultAddDevice.AddNotificationDevice)}>{t('addnotificationdevice')}</Button>
        <Button icon={<PlusOutlined/>} onClick={() => ChooseDevice(ResultAddDevice.AddCounterDevice)}>{t('addcounterdevice')}</Button>
        <Button icon={<PlusOutlined/>} onClick={() => ChooseDevice(ResultAddDevice.AddControlDevice)}>{t('addcontroldevice')}</Button>
      </div>
    </Modal>
  );
}
