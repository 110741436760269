import { ColorType } from '../../Enums/ColorType';

export type ColorProp = {
  KeyName: ColorType;
  ValueKey: string;
  ValueName: string;
  ColorCode: string;
};

export abstract class ColorHelper {
  static options = new Map<string, string>();

  public static GetColors() {
    const dictionary = new Array<ColorProp>();
    dictionary.push({
      KeyName: ColorType.DarkRed,
      ValueKey: 'DarkRed',
      ValueName: 'Donkerrood',
      ColorCode: '#eb4034',
    });
    dictionary.push({
      KeyName: ColorType.Red,
      ValueKey: 'Red',
      ValueName: 'Rood',
      ColorCode: '#fc1808',
    });
    dictionary.push({
      KeyName: ColorType.Orange,
      ValueKey: 'Orange',
      ValueName: 'Oranje',
      ColorCode: '#FFC100',
    });
    dictionary.push({
      KeyName: ColorType.Yellow,
      ValueKey: 'Yellow',
      ValueName: 'Geel',
      ColorCode: '#FFFF00',
    });
    dictionary.push({
      KeyName: ColorType.LightGreen,
      ValueKey: 'LightGreen',
      ValueName: 'Licht groen',
      ColorCode: '#92D051',
    });
    dictionary.push({
      KeyName: ColorType.DarkGreen,
      ValueKey: 'DarkGreen',
      ValueName: 'Donker groen',
      ColorCode: '#06B050',
    });
    dictionary.push({
      KeyName: ColorType.LightBlue,
      ValueKey: 'LightBlue',
      ValueName: 'Licht blauw',
      ColorCode: '#06B0F0',
    });
    dictionary.push({
      KeyName: ColorType.Blue,
      ValueKey: 'Blue',
      ValueName: 'Blauw',
      ColorCode: '#0070C0',
    });
    dictionary.push({
      KeyName: ColorType.DarkBlue,
      ValueKey: 'DarkBlue',
      ValueName: 'Donker blauw',
      ColorCode: '#002060',
    });
    dictionary.push({
      KeyName: ColorType.Purple,
      ValueKey: 'Purple',
      ValueName: 'Paars',
      ColorCode: '#6F309F',
    });
    return dictionary;
  }
}
