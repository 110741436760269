import { authService } from "../App"
import { CounterDevice } from "../Models/Devices/CounterDevice"
import { HttpHelper } from "./HttpHelper"

export abstract class CounterDevicesHelper{
    private static baseUrl = HttpHelper.GetUrlBaseApi() + "api/" + "CounterDevice"
    public static async GetDevices(){
        var urlForRequest = this.baseUrl
        return (await HttpHelper.GetRequestForApi<CounterDevice[]>(urlForRequest))
    }
    
    public static async ReplaceDevices(device: CounterDevice[]){
        var token = authService.GetToken();
        var urlForRequest = this.baseUrl + "/" + "ReplaceAll";
        return (await HttpHelper.PostRequestForApi<CounterDevice>(urlForRequest, device, token));
    }

    public static async UpdateDevice(device: CounterDevice){
        var urlForRequest = this.baseUrl + "/" + device.id
        return (await HttpHelper.UpdateRequestForApi<CounterDevice>(urlForRequest, device))
    }

    public static async AddDevice(device: CounterDevice){
        var urlForRequest = this.baseUrl
        return (await HttpHelper.PostRequestForApi<CounterDevice>(urlForRequest, device))
    }

    public static async DeleteDevice(device: CounterDevice){
        var urlForRequest = this.baseUrl + "/" + device.id
        return (await HttpHelper.DeleteRequestForApi<CounterDevice>(urlForRequest, device))
    }
}