import { BehaviorSubject, Observable } from 'rxjs';
import { ProductionOverview } from '../Models/Production/ProductionOverview';
import { ProductionOverviewHelper } from '../Helpers/ProductionOverviewHelper';

export class ProductionOverviewService {
  public FetchingReady: BehaviorSubject<boolean>;

  public productions!: Array<ProductionOverview>;

  constructor() {
    this.FetchingReady = new BehaviorSubject<boolean>(false);
    this.FetchDevices();
  }

  private async FetchDevices() {
    this.productions = await ProductionOverviewHelper.GetOverview();
    this.FetchingReady.next(true);
  }

  public GetFetchStatus(): Observable<boolean> {
    return this.FetchingReady.asObservable();
  }

  public GetProductions() {
    return this.productions;
  }

  public async UpdateProductionOverview(updatedItem: ProductionOverview) {
    var index = this.productions.findIndex((x) => x.id == updatedItem.id);
    this.productions[index] = updatedItem;
    ProductionOverviewHelper.UpdateOverview(updatedItem);
    return this.productions;
  }

  public async AddProductionOverview(newItem: ProductionOverview) {
    var createdItem = await ProductionOverviewHelper.AddOverview(newItem);
    if (this.productions == null) {
      this.productions = [createdItem];
    } else {
      this.productions.push(createdItem);
    }
    return this.productions;
  }

  public async RemoveProductionOverview(removeItem: ProductionOverview) {
    var index = this.productions.findIndex((x) => x.id == removeItem.id);
    this.productions.splice(index, 1);
    await ProductionOverviewHelper.DeleteOverview(removeItem);
    return this.productions
  }
}
