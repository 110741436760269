import { delay } from 'rxjs';
import { AuthService } from './AuthService';
import { DevicesService } from './DevicesService';
import { LanguageService } from './LanguageService';
import { NotificationService } from './NotificationService';
import { SettingsWebportalService } from './SettingsWebportalService';
import { ThemeService } from './ThemeService';
import { UserService } from './UserService';
import { WebSocketService } from './WebSocketService';
import { ProductionOverviewService } from './ProductionOverviewService';

class ServiceBundler {
  public themeService;

  public languageService;
  public userService;
  public notificationService;
  public devicesService;
  public settingsService;
  public productionOverviewService;

  constructor() {
    this.themeService = new ThemeService();

    this.languageService = new LanguageService();
    this.notificationService = new NotificationService();

    this.userService = new UserService();
    this.settingsService = new SettingsWebportalService();
    this.devicesService = new DevicesService();
    this.productionOverviewService = new ProductionOverviewService();
  }
}

export { ServiceBundler };
