import React, { useEffect, useRef, useState } from 'react';
import { serviceBundler, webSocketService } from '../../App';
import { Badge, FloatButton, Layout, Space, theme } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import { BellOutlined, PlusCircleOutlined } from '@ant-design/icons';

import style from './DevicesPage.module.css';
import { useNavigate } from 'react-router-dom';
import HeaderBar from '../../Components/Header/HeaderBar';
import { useTranslation } from 'react-i18next';
import { MessageWebSocket } from '../../Models/WebSocket/MessageWebSocket';
import { MessageSock } from '../../Models/WebSocket/MessageSock';
import { WebSocketMessageType } from '../../Enums/WebSocketMessageType';
import { WsEspPowerSignalResponce } from '../../Models/WebSocket/WsEspControlPowerResponce';
import { WsEspControlResetResponce } from '../../Models/WebSocket/WsEspControlResetResponce';
import { AddDevice } from '../../Components/Devices/Forms/AddDevice/AddDevice';
import { ResultAddDevice } from '../../Enums/ResultAddDevice';
import CustomLoader from '../../Components/CustomLoader/CustomLoader';
import NotificationDeviceList from '../../Components/Devices/Lists/NotificationDeviceList/NotificationDeviceList';
import CounterDeviceList from '../../Components/Devices/Lists/CounterDeviceList.tsx/CounterDeviceList';
import ContolDeviceList from '../../Components/Devices/Lists/ContolDeviceList/ContolDeviceList';

const { useToken } = theme;

const DevicesPage = () => {
  const { t, i18n } = useTranslation();
  const { token } = useToken();

  let navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [editDevice, setEditDevice] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [countNotification, setCountNotification] = useState(0);

  const notificationDevicesRef = useRef<any>();
  const counterDevicesRef = useRef<any>();
  const controlDevicesRef = useRef<any>();

  useEffect(() => {
    if (loading) {
      HandleIcon();
    }
  }, [loading]);

  const setEditModeCallBack = (value: boolean) => {
    setEditDevice(value);

    const nodeNo = notificationDevicesRef.current;
    if (nodeNo) {
      nodeNo.SetExternalEdit(value);
    }

    const nodeCou = counterDevicesRef.current;
    if (nodeCou) {
      nodeCou.SetExternalEdit(value);
    }

    const nodeCon = controlDevicesRef.current;
    if (nodeCon) {
      nodeCon.SetExternalEdit(value);
    }
  };

  const HandleIcon = () => {
    setLoading(false);

    var countNotificationNumber =
      serviceBundler.notificationService.notificationsProcessed.length;

    serviceBundler.notificationService.notificationsChannel.subscribe(
      (value) => {
        if (Array.isArray(value)) {
          setCountNotification(serviceBundler.notificationService.notificationsProcessed.length);
        }
      },
    );

    serviceBundler.notificationService.notificationsToKillChannel.subscribe(
      (value) => {
        if (Array.isArray(value)) {
          setCountNotification(serviceBundler.notificationService.notificationsProcessed.length);
        }
      },
    );
  };

  const ChooseDevice = (result: ResultAddDevice) => {
    if (result == ResultAddDevice.AddNotificationDevice) {
      const node = notificationDevicesRef.current;
      if (node) {
        node.AddDevice();
      }
    }
    if (result == ResultAddDevice.AddCounterDevice) {
      const node = counterDevicesRef.current;
      if (node) {
        node.AddDevice();
      }
    }
    if (result == ResultAddDevice.AddControlDevice) {
      const node = controlDevicesRef.current;
      if (node) {
        node.AddDevice();
      }
    }
    setEditDevice(true);
    setIsModalOpen(false);
  };

  const NavigateToHome = () => {
    navigate('/');
  };

  const OpenModalForAdd = () => {
    setIsModalOpen(true);
  };

  return (
    <Space direction="vertical" style={{ width: '100%', height: '100vh' }}>
      <Layout>
        <Header className={style.headerStyle}>
          <HeaderBar></HeaderBar>
        </Header>

        <Content
          style={{ backgroundColor: token?.colorPrimaryBg }}
          className={style.headerBlock}
        >
          {!editDevice && (
            <>
              <div className={style.bodyTextBadge}>
                <div
                  style={{ color: token?.colorPrimaryText }}
                  className={style.textBody}
                >
                  {t('connecteddevices')}:
                </div>
                <Badge
                  count={countNotification}
                  showZero
                  color="rgb(193, 210, 2)"
                >
                  <BellOutlined
                    onClick={NavigateToHome}
                    className={style.badgeHover}
                    style={{ fontSize: 25 }}
                  />
                </Badge>
              </div>
            </>
          )}

          {loading && (
            <div
              style={{
                marginTop: 150,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                verticalAlign: 'middle',
              }}
            >
              <CustomLoader></CustomLoader>
            </div>
          )}

          {!loading && (
            <>
              <div className={style.containerDevices}>
                <NotificationDeviceList
                  ref={notificationDevicesRef}
                  isLoading={loading}
                  isEdit={setEditModeCallBack}
                ></NotificationDeviceList>

                <CounterDeviceList
                  ref={counterDevicesRef}
                  isLoading={loading}
                  isEdit={setEditModeCallBack}
                ></CounterDeviceList>

                <ContolDeviceList
                  ref={controlDevicesRef}
                  isLoading={loading}
                  isEdit={setEditModeCallBack}
                ></ContolDeviceList>
              </div>
              <AddDevice
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                onChoose={ChooseDevice}
              ></AddDevice>

              <FloatButton
                onClick={OpenModalForAdd}
                shape="square"
                tooltip={t('adddevice')}
                icon={<PlusCircleOutlined />}
                type="default"
                style={{ right: 40 }}
              />
            </>
          )}
        </Content>
      </Layout>
    </Space>
  );
};

export default DevicesPage;
