import React, { useEffect, useState } from 'react';
import { serviceBundler } from '../../App';
import {
  Button,
  Divider,
  Form,
  InputNumber,
  Layout,
  Popover,
  Select,
  Space,
  Switch,
  Upload,
  UploadProps,
  theme,
} from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import {
  UploadOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

import style from './SettingPage.module.css';
import { useTranslation } from 'react-i18next';
import HeaderBar from '../../Components/Header/HeaderBar';
import { SettingsWebportal } from '../../Models/Settings/SettingsWebportal';
import { SettingsWebportalHelper } from '../../Helpers/SettingsWebportalHelper';
import CustomPopOver from '../../Components/Settings/Popover/CustomPopOver';
import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import { SoundData } from '../../Models/Settings/SoundData';

const { useToken } = theme;

const SettingPage = () => {
  const { t, i18n } = useTranslation();
  const { token } = useToken();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);

  const [settingWebportal, setSettingWebportal] = useState<SettingsWebportal>();
  const [soundPlaying, setSoundPlaying] = useState<boolean>(false);
  const [soundPaused, setSoundPaused] = useState<boolean>(false);
  const [allowAudio, setAllowAudio] = useState<boolean>(false);

  var [audioItem, setAudioItem] = useState<HTMLAudioElement>();

  useEffect(() => {
    if (loading || form) {
      FetchItems();
    }
  }, [form, loading, allowAudio]);

  const FetchItems = () => {
    serviceBundler.settingsService.GetFetchStatus().subscribe((value) => {
      if (!value) {
        return;
      }

      form.resetFields();
      setSettingWebportal(serviceBundler.settingsService.GetSetting());
    });

    var audioAllowed = serviceBundler.notificationService.PlayAudioIsAllowed();
    if (audioAllowed != null) {
      setAllowAudio(audioAllowed);
    } else {
      setAllowAudio(false);
    }

    setLoading(false);
  };

  const onFinishForm = () => {};
  const onFinishFormFailed = (errorInfo: any) => {};

  const GetAvailableSounds = () => {
    if (settingWebportal == undefined) {
      return [];
    }
    if (settingWebportal?.availableSounds == undefined) {
      return [];
    }

    return settingWebportal?.availableSounds.map((x: SoundData) => {
      return { value: x.id, label: x.name };
    });
  };

  const GetMappedAvailableSounds = () => {
    if (settingWebportal == undefined) {
      return [];
    }
    if (settingWebportal?.availableSounds == undefined) {
      return [];
    }

    return settingWebportal?.availableSounds.map((x: SoundData) => {
      return { id: x.id, name: x.name };
    });
  };

  const GetUploadUrl = (): string => {
    return SettingsWebportalHelper.GetUploadUrl();
  };

  const SafeSetting = () => {
    if (settingWebportal) {
      serviceBundler.settingsService.SafeSetting(settingWebportal);
    }
  };

  const SetSound = (id: string) => {
    var newSetting = settingWebportal;
    if (newSetting) {
      newSetting.currentSoundTrack =
        newSetting.availableSounds.find((x) => x.id == id)?.name ?? '';
      setSettingWebportal(newSetting);
      SafeSetting();
    }
  };

  const PossibleToPlay = () => {
    if (
      settingWebportal?.currentSoundTrack == null ||
      settingWebportal?.currentSoundTrack == ''
    ) {
      return true;
    }
    return false;
  };

  const SetTime = (newTime: any) => {
    if (!newTime) {
      return;
    }

    var newSetting = settingWebportal;
    if (newSetting) {
      newSetting.timeForRemoveNotification = Number(newTime);
      setSettingWebportal(newSetting);
      SafeSetting();
    }
  };

  const RemoveSound = (item: string) => {
    var newSetting = settingWebportal;
    if (newSetting) {
      var index = newSetting.availableSounds.findIndex((x) => x.id == item);
      if (index >= 0) {
        var oldSound = newSetting.availableSounds[index];
        if (oldSound.name == newSetting?.currentSoundTrack) {
          newSetting.currentSoundTrack = '';
        }
        serviceBundler.settingsService.RemoveSound(oldSound);
        newSetting.availableSounds.splice(index, 1);
        setSettingWebportal(new SettingsWebportal());
      }
    }
  };

  const HandleChangeUpload: UploadProps['onChange'] = async (
    info: UploadChangeParam<UploadFile>,
  ) => {
    if (info.file.status === 'done') {
      setSettingWebportal(
        await serviceBundler.settingsService.GetSettingUpdated(),
      );
    }
  };

  const PlaySound = () => {
    setSoundPlaying(true);

    if (soundPaused) {
      audioItem?.play();
      setSoundPaused(false);
      return;
    }

    var linkToSound = settingWebportal?.availableSounds.find(
      (x) => x.name == settingWebportal?.currentSoundTrack,
    )?.linkToSound;

    if (linkToSound == undefined) {
      return;
    }
    audioItem = new Audio(linkToSound);

    setSoundPlaying(true);

    audioItem.play();
    audioItem.addEventListener('ended', (event) => {
      setSoundPlaying(false);
    });

    setAudioItem(audioItem);
  };

  const CheckPlayButton = () => {
    if (!soundPlaying || soundPaused) {
      return true;
    }
    return false;
  };

  const CheckPauseButton = () => {
    if (soundPlaying && !soundPaused) {
      return true;
    }
    return false;
  };

  const PauseSound = () => {
    audioItem?.pause();
    setSoundPaused(true);
  };

  const SwitchAudioAllowed = (value: boolean) => {
    setAllowAudio(value)
    serviceBundler.notificationService.AllowPlayAudio(value);
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
      <Layout>
        <Header className={style.headerStyle}>
          <HeaderBar></HeaderBar>
        </Header>

        <Content
          style={{ backgroundColor: token.colorPrimaryBg }}
          className={style.headerBlock}
        >
          <div className={style.content}>
            <Divider>{t('settings')}</Divider>

            <div className={style.centerForm}>
              <Form
                name="basic"
                style={{ width: 500 }}
                initialValues={settingWebportal}
                onFinish={onFinishForm}
                onFinishFailed={onFinishFormFailed}
                autoComplete="off"
                form={form}
              >
                <Form.Item
                  label={t('timeForRemoveNotification') + ' (1-10 sec)'}
                  name="timeForRemoveNotification"
                  key="timeForRemoveNotification"
                  rules={[
                    {
                      required: true,
                      message: t('fillavalidnumber').toString(),
                    },
                  ]}
                >
                  <InputNumber min={1} max={10} onChange={SetTime} />
                </Form.Item>
                <Form.Item
                  label={t('currentsoundtrack')}
                  name="currentSoundTrack"
                  key="currentSoundTrack"
                  rules={[{ required: true, message: 'Vul een naam in' }]}
                >
                  <Select
                    style={{ width: 300 }}
                    onChange={SetSound}
                    options={GetAvailableSounds()}
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Divider style={{ margin: '8px 0' }} />
                        <Space style={{ padding: '0 8px 4px' }}>
                          <Upload
                            action={GetUploadUrl()}
                            accept=".mp3"
                            onChange={HandleChangeUpload}
                          >
                            <Button
                              style={{ marginLeft: 55 }}
                              icon={<UploadOutlined />}
                            >
                              {t('addsound')}
                            </Button>
                          </Upload>
                        </Space>
                      </>
                    )}
                  />
                </Form.Item>
                <Form.Item
                  label={t('removesound')}
                  key="removesound"
                  rules={[{ required: true, message: 'Vul een naam in' }]}
                >
                  <Popover
                    content={
                      <CustomPopOver
                        data={GetMappedAvailableSounds()}
                        removeItem={RemoveSound}
                      />
                    }
                  >
                    <Button
                      style={{ marginLeft: 50 }}
                      icon={<DeleteOutlined />}
                      type="default"
                    >
                      {t('removesound')}
                    </Button>
                  </Popover>
                </Form.Item>
                <Form.Item label={t('testsound')} key="testsound">
                  {CheckPlayButton() && (
                    <Button
                      disabled={PossibleToPlay()}
                      style={{ marginLeft: 82 }}
                      icon={<PlayCircleOutlined />}
                      onClick={() => PlaySound()}
                    >
                      {t('playsound')}
                    </Button>
                  )}
                  {CheckPauseButton() && (
                    <Button
                      style={{ marginLeft: 82 }}
                      icon={<PauseCircleOutlined />}
                      onClick={() => PauseSound()}
                    >
                      {t('pausesound')}
                    </Button>
                  )}
                </Form.Item>
                <Form.Item
                  initialValue={allowAudio}
                  label={t('audioallowed')}
                  key="audioallowed"
                  valuePropName="checked"
                >
                  <div style={{marginLeft: 40}}>
                    {allowAudio && (
                      <Button onClick={() => SwitchAudioAllowed(false)}>
                        {t('enabled')}
                      </Button>
                    )}
                    {!allowAudio && (
                      <Button onClick={() => SwitchAudioAllowed(true)}>
                        {t('disabled')}
                      </Button>
                    )}
                  </div>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Content>
      </Layout>
    </Space>
  );
};

export default SettingPage;
