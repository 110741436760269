import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Button, Form, Input, InputNumber, Table, Typography } from 'antd';
import { TranslationLabelKey } from '../../../Models/Language/TranslationLabelKey';
import { useTranslation } from 'react-i18next';

import { PlusCircleOutlined } from '@ant-design/icons';
import { ProductionOverview } from '../../../Models/Production/ProductionOverview';
import { ColumnsType } from 'antd/es/table';

import style from './TableProduction.module.css';

import './TableProduction.module.css';
import moment from 'moment';

export const TableProduction = forwardRef(
  (
    props: {
      dataSource?: ProductionOverview[];
      clickRow: Function;
    },
    ref,
  ) => {
    const { i18n, t } = useTranslation();

    const [productions, setProductions] = useState<ProductionOverview[]>([]);
    const [productionsTemp, setProductionsTemp] = useState<ProductionOverview[]>([]);

    useImperativeHandle(ref, () => ({
      SetSearchText(text: string) {
        if (text.length == 0) {
          setProductions([...productionsTemp]);
          return;
        }
        var filteredData = productionsTemp.filter((x) => {
          return (
            x.typeName.toLocaleLowerCase().includes(text)
          );
        });
        setProductions(filteredData);
      },
    }));

    useEffect(() => {
      if (props.dataSource != undefined) {
        setProductions(props.dataSource);
        setProductionsTemp(props.dataSource);
      }
    }, [props.dataSource]);

    const ClickRow = (data: ProductionOverview) => {
      props.clickRow(data);
    };

    const columns: ColumnsType<ProductionOverview> = [
      {
        title: t('name'),
        dataIndex: 'typeName',
        key: 'typeName',
        // render: (text) => <a>{text}</a>,
      },
      {
        title: t('timestampstart'),
        dataIndex: 'timeStampStart',
        key: 'timeStampStart',
        sorter: (a, b) => moment(a.timeStampStart).unix() - moment(b.timeStampStart).unix()

      },
      {
        title: t('timestampstop'),
        dataIndex: 'timeStampStop',
        key: 'timeStampStop',
        sorter: (a, b) => moment(a.timeStampStop).unix() - moment(b.timeStampStop).unix()

      },
      {
        title: t('quantitytrays'),
        dataIndex: 'quantityTrays',
        key: 'quantityTrays',
        sorter: (a, b) => a.quantityTrays - b.quantityTrays,
      },
    ];

    return (
      <Table
      size='small'
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => ClickRow(record),
          };
        }}
        rowClassName={style.hoverRow}
        columns={columns}
        dataSource={productions}
      />
    );
  },
);
