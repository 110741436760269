import { TypeModule } from "./TypeModule";

export class BaseDevice{
  id!: string
  moduleId!: string
  moduleName!: string;
  typeModule!: TypeModule;
  ipAddress!: string
  online: boolean = false
  lastOnline!: Date
}

