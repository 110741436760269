import React, { useEffect, useRef, useState } from 'react';
import { Button, Layout, Modal, Space, theme } from 'antd';

import style from './HomePage.module.css';

import { Header } from 'antd/es/layout/layout';
import { useTranslation } from 'react-i18next';
import HomeHeaderBar from '../../Components/HomeHeader/HomeHeaderBar';
import { serviceBundler } from '../../App';
import { NotificationModule } from '../../Models/Notifications/NotificationMachine';
import { MyList } from '../../Components/CustomAnimatedList/CustomAnimatedList';

const { Content } = Layout;
const { useToken } = theme;

const Home = () => {
  const { t, i18n } = useTranslation();
  const { token } = useToken();

  const myContainer = useRef(null);

  const notificationRef = useRef<any>();

  useEffect(() => {
    serviceBundler.notificationService.notificationsChannel.subscribe(
      (value) => {
        if (Array.isArray(value)) {
          AddItems(value);
        }
      },
    );

    serviceBundler.notificationService.notificationsToKillChannel.subscribe(
      (value) => {
        if (Array.isArray(value)) {
          RemoveItems(value);
        }
      },
    );

    if (serviceBundler.notificationService.notificationsProcessed.length >= 1) {
      AddItems(serviceBundler.notificationService.notificationsProcessed);
    }

  }, [0]);

  const AddItems = (items: NotificationModule[]) => {
    const node = notificationRef.current;
    if (node) {
      node.AddNotifications(items);
    }
  };

  const RemoveItems = (items: NotificationModule[]) => {
    const node = notificationRef.current;
    if (node) {
      node.RemoveNotifications(items);
    }
  };

  const RemoveAllNotifications = () => {
    const node = notificationRef.current;
    if (node) {
      node.RemoveAllNotifications(
        serviceBundler.notificationService.notificationsProcessed,
      );
    }

    serviceBundler.notificationService.notificationsProcessed = [];
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
      <Layout>
        <Header className={style.headerStyle}>
          <HomeHeaderBar></HomeHeaderBar>
        </Header>
        <Content
          style={{ backgroundColor: token.colorPrimaryBg }}
          className={style.contentBlock}
        >
          <MyList ref={notificationRef}></MyList>

          <Button
            onClick={RemoveAllNotifications}
            type="primary"
            size="large"
            className={style.btnRemoveMsg}
          >
            {t('removeallmessages')}
          </Button>
        </Content>
      </Layout>
    </Space>
  );
};

export default Home;
