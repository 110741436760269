import { BaseDevice } from './BaseDevice';
import { DbConnectedSensor } from './DbConnectedSensor';

export class NotificationDevice extends BaseDevice {
  constructor() {
    super();
  }

  enableNotifications: boolean = false;
  enableSound: boolean = false;
  visible: boolean = false;
  connectedSensors: Array<DbConnectedSensor> = [];
}