import {
  Input,
  Form,
  Switch,
  InputNumber,
  Select,
  Button,
  Divider,
  Popconfirm,
} from 'antd';
import { DbConnectedSensor } from '../../../../Models/Devices/DbConnectedSensor';
import { useTranslation } from 'react-i18next';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  ColorHelper,
  ColorProp,
} from '../../../../Models/Devices/ColorOptions';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import style from './EditNotificationSensor.module.css';
import { AddSpecialFunction } from '../AddSpecialFunction/AddSpecialFunction';
import { ResultAddDevice } from '../../../../Enums/ResultAddDevice';
import { ResultAddFunction } from '../../../../Enums/ResultAddFunction';
import { ExternalPowerState } from '../../../../Models/Devices/ExternalPowerState';
import { ExternalPowerStateLinked } from '../../../../Models/Devices/ExternalPowerStateLinked';
import { ExternalShutdownSignal } from '../../../../Models/Devices/ExternalShutdownSignal';
import Typography from 'antd/es/typography/Typography';
import { TrayCounter } from '../../../../Models/Devices/TrayCounter';

const CustomColorBlock = (props: { color: ColorProp }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div
        style={{
          marginTop: 'auto',
          marginBottom: 'auto',
          marginRight: 10,
          height: 10,
          width: 10,
          borderRadius: 3,
          backgroundColor: props.color.ColorCode,
        }}
      ></div>
      <div>{props.color.ValueName}</div>
    </div>
  );
};

export const EditNotificationSensor = forwardRef(
  (
    props: {
      allData?: DbConnectedSensor[];
      data?: DbConnectedSensor;
      onCancel: Function;
      onRemove: Function;
      onSafe: Function;
    },
    ref,
  ) => {
    const { t, i18n } = useTranslation();
    const [form] = Form.useForm();

    const [connectedSensor, setConnectedSensor] = useState<DbConnectedSensor>();
    const [showNotiData, setShowNotiData] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useImperativeHandle(ref, () => ({
      SafeSensor() {
        if (connectedSensor) {
          form.submit();
          form.validateFields({ validateOnly: true }).then(
            (values) => {
              var newObj: DbConnectedSensor = Object.assign(
                new DbConnectedSensor(),
                values,
              );

              if (connectedSensor.trayCounter) {
                newObj.trayCounter = new TrayCounter();
                var valueTrayCounter = form.getFieldValue('numberOfTrays');
                newObj.trayCounter.numberOfTrays = valueTrayCounter;
                var valueLinkedIdTrayCounter = form.getFieldValue(
                  'linkedSensorIdTrayCounter',
                );
                newObj.trayCounter.linkedSensorId = valueLinkedIdTrayCounter;
              }
              newObj.id = connectedSensor.id;
              if (connectedSensor.externalPowerState) {
                newObj.externalPowerState = new ExternalPowerState();
              }
              if (connectedSensor.externalShutdownSignal) {
                newObj.externalShutdownSignal = new ExternalShutdownSignal();
              }
              if (connectedSensor.externalPowerStateLinked) {
                newObj.externalPowerStateLinked =
                  new ExternalPowerStateLinked();
                var valueExternalPowerState =
                  form.getFieldValue('linkedSensorId');
                newObj.externalPowerStateLinked.sensorId =
                  valueExternalPowerState;
                var valueLinkedSwitchValue = form.getFieldValue(
                  'linkedSwitchSensorValue',
                );
                newObj.externalPowerStateLinked.switchSensorValue =
                  valueLinkedSwitchValue;
              }

              props.onSafe(newObj);
            },
            () => {
              props.onSafe(null);
            },
          );
        }
      },
    }));

    useEffect(() => {
      form.resetFields();
      setConnectedSensor(props.data);
      InitLooseValues();
      setShowNotiData(false);
      if (props.data?.showNotification) {
        setShowNotiData(true);
      }
    }, [form, props.data, connectedSensor]);

    const InitLooseValues = () => {
      form.setFieldValue(
        'numberOfTrays',
        connectedSensor?.trayCounter?.numberOfTrays,
      );

      form.setFieldValue(
        'linkedSensorIdTrayCounter',
        connectedSensor?.trayCounter?.linkedSensorId,
      );

      form.setFieldValue(
        'linkedSensorId',
        connectedSensor?.externalPowerStateLinked?.sensorId,
      );

      form.setFieldValue(
        'linkedSwitchSensorValue',
        connectedSensor?.externalPowerStateLinked?.switchSensorValue,
      );
    };

    const onFinishForm = (item: DbConnectedSensor) => {
      props.onSafe(item);
    };

    const onFinishFormFailed = (errorInfo: any) => {};

    const checkLabelExist = (_: any, value: string) => {
      var foundItem = props.allData?.find((x) => x.moduleName === value);
      if (foundItem !== undefined) {
        if (foundItem.sensorId === props.data?.sensorId) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('Deze email bestaat al'));
      }
      return Promise.resolve();
    };

    const messageValidation = () => {
      return t('fillthefield').toString();
    };

    const GetAvailableColors = () => {
      var colors = ColorHelper.GetColors();
      return colors.map((color) => {
        return {
          value: color.KeyName,
          label: <CustomColorBlock color={color}></CustomColorBlock>,
        };
      });
    };

    const ShowNotification = (value: boolean) => {
      setShowNotiData(value);
    };

    const onConfirmRemove = () => {
      props.onRemove(connectedSensor);
    };

    const ChooseFunction = (result: ResultAddFunction) => {
      if (!connectedSensor) {
        return;
      }

      if (result == ResultAddFunction.AddTrayCounter) {
        connectedSensor.trayCounter = new TrayCounter();
        setConnectedSensor(connectedSensor);
      }
      if (result == ResultAddFunction.AddExternalPowerState) {
        connectedSensor.externalPowerState = new ExternalPowerState();
        setConnectedSensor(connectedSensor);
      }
      if (result == ResultAddFunction.AddExternalPowerStateLinked) {
        connectedSensor.externalPowerStateLinked =
          new ExternalPowerStateLinked();
        setConnectedSensor(connectedSensor);
      }
      if (result == ResultAddFunction.AddExternalShutdownSignal) {
        connectedSensor.externalShutdownSignal = new ExternalShutdownSignal();
        setConnectedSensor(connectedSensor);
      }

      setIsModalOpen(false);
    };

    const RemoveExternalState = () => {
      if (connectedSensor) {
        connectedSensor.externalPowerState = null;
        setConnectedSensor(connectedSensor);
        form.submit();
      }
    };

    const RemoveExternalShutdown = () => {
      if (connectedSensor) {
        connectedSensor.externalShutdownSignal = null;
        setConnectedSensor(connectedSensor);
        form.submit();
      }
    };

    const RemoveExternalLinked = () => {
      if (connectedSensor) {
        connectedSensor.externalPowerStateLinked = null;
        setConnectedSensor(connectedSensor);
        form.submit();
      }
    };

    return (
      <>
        <AddSpecialFunction
          open={isModalOpen}
          sensor={connectedSensor}
          onCancel={() => setIsModalOpen(false)}
          onChoose={ChooseFunction}
        ></AddSpecialFunction>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
          className={style.devider}
        >
          <Divider
            style={{
              marginRight: 10,
            }}
            // orientation="left"
            // orientationMargin="250"
          >
            {t('edit')}
          </Divider>
          <Button
            style={{ marginTop: 12, marginLeft: 10, width: '50px' }}
            icon={
              <Popconfirm
                title={t('removesensor')}
                description={t('areyousure')}
                onConfirm={onConfirmRemove}
                okText={t('yes')}
                cancelText={t('no')}
              >
                <DeleteOutlined style={{ paddingLeft: 6, paddingRight: 6 }} />
              </Popconfirm>
            }
          ></Button>
          <Button
            style={{ marginTop: 12, marginLeft: 20, width: '50px' }}
            onClick={() => setIsModalOpen(true)}
            icon={<PlusOutlined style={{ paddingLeft: 6, paddingRight: 6 }} />}
          ></Button>
        </div>

        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={connectedSensor}
          onFinish={onFinishForm}
          onFinishFailed={onFinishFormFailed}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            label={t('modulename')}
            name="moduleName"
            key="moduleName"
            rules={[
              {
                message: messageValidation(),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('sensorName')}
            name="sensorName"
            key="sensorName"
            rules={[{ required: true, message: messageValidation() }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('sensorid')}
            name="sensorId"
            key="sensorId"
            rules={[
              { required: true, message: messageValidation() },
              { validator: checkLabelExist },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('visibleenvironment')}
            name="visible"
            key="visible"
            valuePropName="checked"
            rules={[{ required: true, message: messageValidation() }]}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label={t('shownotification')}
            name="showNotification"
            key="showNotification"
            valuePropName="checked"
            rules={[{ required: true, message: messageValidation() }]}
          >
            <Switch onChange={ShowNotification} />
          </Form.Item>
          <Form.Item
            label={t('switchinputvalue')}
            name="switchInputValue"
            key="switchInputValue"
            valuePropName="checked"
            rules={[{ required: true, message: messageValidation() }]}
          >
            <Switch />
          </Form.Item>
          {showNotiData && (
            <>
              <Form.Item
                label={t('symbolForUrl')}
                name="symbolForUrl"
                key="symbolForUrl"
                rules={[{ required: showNotiData }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('valuetrue')}
                name="valueTrue"
                key="valueTrue"
                rules={[{ required: showNotiData }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('reactiontimetrue') + ' (1-10)'}
                name="reactionTimeTrue"
                key="reactionTimeTrue"
                rules={[{ required: showNotiData }]}
              >
                <InputNumber min={1} max={10} />
              </Form.Item>
              <Form.Item
                label={t('valuetruecolor')}
                name="valueTrueColor"
                key="valueTrueColor"
                rules={[{ required: showNotiData }]}
              >
                <Select options={GetAvailableColors()}>
                  <Select.Option value="demo">Demo</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label={t('valuefalse')}
                name="valueFalse"
                key="valueFalse"
                rules={[{ required: showNotiData }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('reactiontimefalse') + ' (1-10)'}
                name="reactionTimeFalse"
                key="reactionTimeFalse"
                rules={[{ required: showNotiData }]}
              >
                <InputNumber min={1} max={10} />
              </Form.Item>
              <Form.Item
                label={t('valuefalsecolor')}
                key="valueFalseColor"
                name="valueFalseColor"
                rules={[{ required: showNotiData }]}
              >
                <Select options={GetAvailableColors()}>
                  <Select.Option value="demo">Demo</Select.Option>
                </Select>
              </Form.Item>
            </>
          )}

          {connectedSensor?.trayCounter && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
                className={style.devider}
              >
                <Divider orientation="left" orientationMargin={150}>
                  {t('traycounter')}
                </Divider>
                <Button
                  style={{ marginTop: 12, marginLeft: 50, width: '50px' }}
                  onClick={RemoveExternalLinked}
                  icon={
                    <DeleteOutlined
                      style={{ paddingLeft: 6, paddingRight: 6 }}
                    />
                  }
                ></Button>
              </div>
              <Form.Item
                label={t('numberoftrays')}
                name="numberOfTrays"
                key="numberOfTrays"
                rules={[{ required: true, message: messageValidation() }]}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                label={t('sensorid')}
                name="linkedSensorIdTrayCounter"
                key="linkedSensorIdTrayCounter"
                rules={[{ required: true, message: messageValidation() }]}
              >
                <Input />
              </Form.Item>
            </>
          )}

          {connectedSensor?.externalPowerState && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 20,
              }}
            >
              <Typography>{t('externalpowerstate')}</Typography>
              <Button
                onClick={RemoveExternalState}
                icon={<DeleteOutlined />}
              ></Button>
            </div>
          )}

          {connectedSensor?.externalShutdownSignal && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 20,
              }}
            >
              <Typography>{t('externalshutdownsignal')}</Typography>
              <Button
                onClick={RemoveExternalShutdown}
                icon={<DeleteOutlined />}
              ></Button>
            </div>
          )}

          {connectedSensor?.externalPowerStateLinked && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
                className={style.devider}
              >
                <Divider orientation="left" orientationMargin={150}>
                  {t('externalpowerstatelinked')}
                </Divider>
                <Button
                  style={{ marginTop: 12, marginLeft: 50, width: '50px' }}
                  onClick={RemoveExternalLinked}
                  icon={
                    <DeleteOutlined
                      style={{ paddingLeft: 6, paddingRight: 6 }}
                    />
                  }
                ></Button>
              </div>
              <Form.Item
                label={t('sensorid')}
                name="linkedSensorId"
                key="linkedSensorId"
                rules={[{ required: true, message: messageValidation() }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('switchinputvalue')}
                name="linkedSwitchSensorValue"
                key="linkedSwitchSensorValue"
                valuePropName="checked"
                rules={[{ required: true, message: messageValidation() }]}
              >
                <Switch />
              </Form.Item>
            </>
          )}
        </Form>
      </>
    );
  },
);
