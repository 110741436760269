import { Header } from 'antd/es/layout/layout';
import { Button, notification, theme } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

import { useEffect, useState } from 'react';

import style from './HomeHeaderBar.module.css';
import { useTranslation } from 'react-i18next';

import { BarsOutlined } from '@ant-design/icons';
const { useToken } = theme;

const HomeHeaderBar = () => {
  let navigate = useNavigate();

  const { i18n, t } = useTranslation();
  const { token } = useToken();
  const [api, contextHolder] = notification.useNotification();

  const NavigateToDevices = () => {
    navigate('/devices');
  };

  useEffect(() => {}, [0]);

  return (
    <div
      style={{ backgroundColor: token.colorPrimaryBg }}
      className={style.columnNames}
    >
      <div className={style.columnSpace}></div>
      <div className={style.columnMachine}>{t('machine')}</div>
      <div className={style.columnMessage}>{t('message')}</div>
      <div className={style.columnSetting}>
        <Button
          onClick={NavigateToDevices}
          type="ghost"
          style={{ width: 30, height: 30 }}
          icon={
            <BarsOutlined
              className={style.btnSetting}
              style={{ fontSize: 25 }}
            />
          }
        ></Button>
      </div>
    </div>
  );
};

export default HomeHeaderBar;
