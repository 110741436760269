import { Input, Select, Form, Switch, Divider } from 'antd';
import { NotificationDevice } from '../../../../Models/Devices/NotificationDevice';
import { useTranslation } from 'react-i18next';
import { TypeModule } from '../../../../Models/Devices/TypeModule';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

export const EditNotificationGlobal = forwardRef(
  (
    props: {
      allData?: NotificationDevice[];
      data?: NotificationDevice;
      onCancel: Function;
      onSafe: Function;
    },
    ref,
  ) => {
    const { t, i18n } = useTranslation();
    const [form] = Form.useForm();

    const [globalData, setGlobalData] = useState<NotificationDevice>();

    useImperativeHandle(ref, () => ({
      SafeGlobal() {
        form.submit();
        form.validateFields({ validateOnly: true }).then(
          (values) => {
            values.id = props.data!.id;
            values.connectedSensors = props.data!.connectedSensors;
            props.onSafe(values);
          },
          () => {
            props.onSafe(null);
          },
        );
      },
    }));

    useEffect(() => {
      form.resetFields();
      setGlobalData(props.data);
    }, [form, props.data, globalData]);

    const onFinishForm = (item: any) => {
    };
    const onFinishFormFailed = (errorInfo: any) => {
    };

    const checkLabelExist = (_: any, value: string) => {
      var foundItem = props.allData?.find((x) => x.moduleName === value);
      if (foundItem !== undefined) {
        if (foundItem.id === props.data?.id) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('Deze email bestaat al'));
      }
      return Promise.resolve();
    };

    const GetModuleType = () => {
      return [
        { value: TypeModule.Logo, label: 'Logo' },
        { value: TypeModule.Esp32, label: 'Esp 32' },
      ];
    };

    return (
      <>
        <Divider>{t('edit')}</Divider>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={globalData}
          onFinish={onFinishForm}
          onFinishFailed={onFinishFormFailed}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            label={t('modulename')}
            name="moduleName"
            key="moduleName"
            required
            rules={[
              {
                required: true,
                message: 'Email is vereist',
              },
              { validator: checkLabelExist },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('moduleid')}
            name="moduleId"
            key="moduleId"
            required
            rules={[
              {
                required: true,
                message: 'Email is vereist',
              },
              { validator: checkLabelExist },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('typemodule')}
            name="typeModule"
            key="typeModule"
            rules={[{ required: true, message: 'Vul een naam in' }]}
          >
            <Select options={GetModuleType()}>
              <Select.Option value="demo">Demo</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={t('enablenotifications')}
            name="enableNotifications"
            key="enableNotifications"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label={t('enablesound')}
            name="enableSound"
            key="enableSound"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label={t('visible')}
            name="visible"
            key="visible"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Form>
      </>
    );
  },
);
