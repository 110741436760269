import {
  Button,
  DatePicker,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Modal,
} from 'antd';
import { useEffect, useState } from 'react';
import { UserModel } from '../../../Models/Auth/UserModel';
import { useTranslation } from 'react-i18next';
import { ProductionOverview } from '../../../Models/Production/ProductionOverview';
import type { DatePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';

import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(weekday);
dayjs.extend(localeData);

const RemoveButton = (props: {
  bindedFunction: Function;
  bindedForm: FormInstance;
}) => {
  return (
    <>
      <Button
        key="link"
        type="primary"
        onClick={() => {
          props.bindedFunction(true);
          props.bindedForm.submit();
        }}
      >
        Verwijder
      </Button>
    </>
  );
};

const dateFormat = 'YYYY/MM/DD';

export function AddProduction(props: {
  allData: ProductionOverview[];
  data: ProductionOverview;
  open: boolean;
  onCancel: Function;
  onSafe: Function;
  onRemove: Function;
  onEdit: boolean;
}) {
  const [form] = Form.useForm();
  const { i18n, t } = useTranslation();

  const [removeItem, setRemoveItem] = useState(false);
  const [production, setProduction] = useState<ProductionOverview>();

  useEffect(() => {
    form.resetFields();

    if (props.data.id == undefined) {
      var newDate = dayjs().toDate();
      props.data.timeStampStart = newDate.toDateString();
    }

    setProduction(props.data);
    setRemoveItem(false);
  }, [form, props.data, production]);

  const handleCancel = () => {
    props.onCancel();
  };

  const onFinishForm = (values: ProductionOverview) => {
    if (props.data?.id !== undefined) {
      values.id = props.data?.id;
    }

    var newProd = AsignProduction(values);

    if (removeItem) {
      props.onRemove(newProd);
    } else {
      props.onSafe(newProd);
    }
  };

  const AsignProduction = (item: ProductionOverview) => {
    var newProd = new ProductionOverview();
    newProd.id = item.id;
    newProd.typeName = item.typeName;
    newProd.timeStampStart = dayjs(item.timeStampStart).format(
      'YYYY-MM-DDTHH:mm:ssZ',
    );
    newProd.timeStampStop = dayjs(item.timeStampStop).format(
      'YYYY-MM-DDTHH:mm:ssZ',
    );
    newProd.quantityTrays = item.quantityTrays;
    return newProd;
  };

  const onFinishFormFailed = (errorInfo: any) => {};

  const RenderRemoveButton = () => {
    if (production?.id !== undefined) {
      return (
        <RemoveButton
          bindedFunction={setRemoveItem}
          bindedForm={form}
        ></RemoveButton>
      );
    }
    return <></>;
  };

  const onChangeStartDate = (
    value: DatePickerProps['value'],
    dateString: [string, string] | string,
  ) => {
    if (typeof dateString === 'string') {
      var newDate = dayjs(dateString).toDate();
      production!.timeStampStart = newDate.toDateString();
      setProduction(production);
    }
  };

  const onChangeStopDate = (
    value: DatePickerProps['value'],
    dateString: [string, string] | string,
  ) => {
    if (typeof dateString === 'string') {
      var newDate = dayjs(dateString).toDate();
      production!.timeStampStop = newDate.toDateString();
      setProduction(production);
    }
  };

  const AddEndTime = () => {
    if (production) {
      production.timeStampStop = dayjs().toDate().toDateString();
      setProduction(undefined);
    }
  };

  const ShowEndTime = () => {
    if (production?.timeStampStop) {
      return true;
    }
    return false;
  };

  return (
    <Modal
      title={production?.id ? t('editproduction') : t('addproduction')}
      open={props.open}
      onOk={() => form.submit()}
      okText={t('safe')}
      cancelText={t('cancel')}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          {t('cancel')}
        </Button>,
        <>
          {RenderRemoveButton()}

          <Button key="submit" type="primary" onClick={() => form.submit()}>
            {t('safe')}
          </Button>
        </>,
      ]}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={props.data}
        onFinish={onFinishForm}
        onFinishFailed={onFinishFormFailed}
        autoComplete="off"
        form={form}
        key={'addprod'}
      >
        <Form.Item
          label={t('typename')}
          name="typeName"
          key="typeName"
          required
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('timestampstart')}
          key="timeStampStart"
          name="timeStampStart"
          rules={[{ required: true }]}
          getValueProps={(i: string) => ({
            value: dayjs(i).hour(dayjs(i).hour()),
          })}
        >
          <DatePicker showTime onChange={onChangeStartDate} />
        </Form.Item>
        {ShowEndTime() && (
          <Form.Item
            label={t('timestampstop')}
            key="timeStampStop"
            name="timeStampStop"
            rules={[{ required: true }]}
            getValueProps={(i: string) => ({
              value: dayjs(i).hour(dayjs(i).hour()),
            })}
          >
            <DatePicker showTime onChange={onChangeStopDate} />
          </Form.Item>
        )}
        {!ShowEndTime() && (
          <>
            <Form.Item label={t('timestampstop')} key="timeStampStop">
              <Button onClick={AddEndTime}>{t('addendtime')}</Button>
            </Form.Item>
          </>
        )}
        <Form.Item
          label={t('quantitytrays')}
          name="quantityTrays"
          key="quantityTrays"
          rules={[{ required: true }]}
        >
          <InputNumber />
        </Form.Item>
      </Form>
    </Modal>
  );
}
