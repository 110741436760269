import {
  Input,
  Form,
  Switch,
  InputNumber,
  Select,
  Button,
  Divider,
  Popconfirm,
} from 'antd';
import { DbConnectedSensor } from '../../../../Models/Devices/DbConnectedSensor';
import { useTranslation } from 'react-i18next';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  ColorHelper,
  ColorProp,
} from '../../../../Models/Devices/ColorOptions';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import style from './EditSwitch.module.css';
import { ConnectedSwitch } from '../../../../Models/Devices/DbConnectedSwitch';

const CustomColorBlock = (props: { color: ColorProp }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div
        style={{
          marginTop: 'auto',
          marginBottom: 'auto',
          marginRight: 10,
          height: 10,
          width: 10,
          borderRadius: 3,
          backgroundColor: props.color.ColorCode,
        }}
      ></div>
      <div>{props.color.ValueName}</div>
    </div>
  );
};

export const EditSwitch = forwardRef(
  (
    props: {
      allData?: ConnectedSwitch[];
      data?: ConnectedSwitch;
      onCancel: Function;
      onRemove: Function;
      onSafe: Function;
    },
    ref,
  ) => {
    const { t, i18n } = useTranslation();
    const [form] = Form.useForm();

    const [connectedSwitch, setConnectedSwitch] = useState<ConnectedSwitch>();
    const [isModalOpen, setIsModalOpen] = useState(false);

    useImperativeHandle(ref, () => ({
      SafeSensor() {
        if (connectedSwitch) {
          form.submit();
          form.validateFields({ validateOnly: true }).then(
            (values) => {
              var newObj: ConnectedSwitch = Object.assign(
                new ConnectedSwitch(),
                values,
              );
              newObj.id = connectedSwitch.id;

              props.onSafe(newObj);
            },
            () => {
              props.onSafe(null);
            },
          );
        }
      },
    }));

    useEffect(() => {
      form.resetFields();
      setConnectedSwitch(props.data);
    }, [form, props.data, connectedSwitch]);

    const onFinishForm = (item: DbConnectedSensor) => {
      props.onSafe(item);
    };

    const onFinishFormFailed = (errorInfo: any) => {};

    const checkLabelExist = (_: any, value: string) => {
      var foundItem = props.allData?.find((x) => x.switchName === value);
      if (foundItem !== undefined) {
        if (foundItem.switchId === props.data?.switchId) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('Deze email bestaat al'));
      }
      return Promise.resolve();
    };

    const messageValidation = () => {
      return t('fillthefield').toString();
    };

    const GetAvailableColors = () => {
      var colors = ColorHelper.GetColors();
      return colors.map((color) => {
        return {
          value: color.KeyName,
          label: <CustomColorBlock color={color}></CustomColorBlock>,
        };
      });
    };

    const onConfirmRemove = () => {
      props.onRemove(connectedSwitch);
    };

    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
          className={style.devider}
        >
          <Divider
            style={{
              marginRight: 10,
            }}
            // orientation="left"
          >
            {t('edit')}
          </Divider>
          <Button
            style={{ marginTop: 12, marginLeft: 10, width: '50px' }}
            icon={
              <Popconfirm
                title={t('removesensor')}
                description={t('areyousure')}
                onConfirm={onConfirmRemove}
                okText={t('yes')}
                cancelText={t('no')}
              >
                <DeleteOutlined style={{ paddingLeft: 6, paddingRight: 6 }} />
              </Popconfirm>
            }
          ></Button>
          <Button
            style={{ marginTop: 12, marginLeft: 20, width: '50px' }}
            onClick={() => setIsModalOpen(true)}
            icon={<PlusOutlined style={{ paddingLeft: 6, paddingRight: 6 }} />}
          ></Button>
        </div>

        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={connectedSwitch}
          onFinish={onFinishForm}
          onFinishFailed={onFinishFormFailed}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            label={t('switchid')}
            name="switchId"
            key="switchId"
            rules={[{ required: true, message: messageValidation() }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('switchname')}
            name="switchName"
            key="switchName"
            rules={[
              {
                message: messageValidation(),
              },
              { validator: checkLabelExist },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('currentpowerstate')}
            name="currentPowerState"
            key="currentPowerState"
            valuePropName="checked"
            rules={[{ required: true, message: messageValidation() }]}
          >
            <Switch disabled={true} />
          </Form.Item>
          <Form.Item
            label={t('hourtostart')}
            name="hourToStart"
            key="hourToStart"
            rules={[
              { required: true, message: messageValidation() },
              { validator: checkLabelExist },
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label={t('hourtostop')}
            name="hourToStop"
            key="hourToStop"
            rules={[
              { required: true, message: messageValidation() },
              { validator: checkLabelExist },
            ]}
          >
            <InputNumber />
          </Form.Item>
        </Form>
      </>
    );
  },
);
