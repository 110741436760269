/** This NotificationMachine API interface is used to configure and display desktop notifications to the user. */
export class NotificationModule {
  Id!: number;
  timeStamp: number;
  sensorId!: string;
  sensorName!: string;
  moduleName!: string;
  message!: string;
  fixed!: boolean
  icon!: string;
  SymbolForUrl!: string;
  type?: string;
  color?: string;

  constructor(
    icon: string, 
    sensorId: string,
    moduleName: string, 
    fixed: boolean, 
    message: string, 
    symbolForUrl: string, 
    color: string,
    type: string
    ) {
    this.Id = 0;
    this.timeStamp = new Date().getTime() / 1000
    this.icon = icon;
    this.sensorId = sensorId;
    this.moduleName = moduleName;
    this.fixed = fixed;
    this.message = message;
    this.SymbolForUrl = symbolForUrl;
    this.color = color;
    this.type = type;
  }
}
