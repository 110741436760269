import settings from '../appsettings.json';
import versionInfo from '../versioninfo.json';

export abstract class LocalHelper{
    public static SafeValueWithKey(key: string, value: string){
        localStorage.setItem(key, value)
    }

    public static GetValueByKey(key: string){
        return localStorage.getItem(key)
    }

    public static RemoveKey(key: string){
        return localStorage.removeItem(key)
    }

    public static GetLanguageKey(){
        var key = localStorage.getItem('languageKey')
        if (key == null){
            return 'nl'
        }
        return key
    }
    
    public static GetUrlForSocketRemote(): string{
        return settings.endpointsocketremote;
    }

    public static GetUrlForSocketLocal(): string{
        return settings.endpointsocketlocal
    }

    public static GetUrlForSocketLocalHost(): string{
        return settings.endpointsocketlocalhost
    }

    public static GetUrlForApiRemote(): string{
        return settings.endpointapiremote;
    }

    public static GetUrlForApiRemoteHome(): string {
        return settings.endpointapiremotehome;
      }

    public static GetUrlForApiLocal(): string{
        return settings.endpointapilocal
    }

    public static GetUrlForApiLocalHome(): string {
        return settings.endpointapilocalhome;
      }

    public static GetUrlForApiLocalHost(): string{
        return settings.endpointapilocalhost;
    }

    public static GetUrlForStorageLocal(): string{
        return settings.endpointstoragelocal
    }

    public static GetUrlForStorageRemote(): string{
        return settings.endpointstorageremote;
    }

    public static GetVersionInfo(): any {
        return versionInfo;
      }
}