import {
  Space,
  Layout,
  Row,
  Col,
  Divider,
  Drawer,
  Button,
  theme,
  Select,
} from 'antd';
import { Header, Content } from 'antd/es/layout/layout';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import style from './ProductionPage.module.css';
import { useParams } from 'react-router-dom';

import { ArrowLeftOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import { useTranslation } from 'react-i18next';
import { PlusCircleOutlined, ArrowsAltOutlined } from '@ant-design/icons';
import HeaderBar from '../../Components/Header/HeaderBar';
import { serviceBundler } from '../../App';
import { ProductionOverview } from '../../Models/Production/ProductionOverview';
import { TableProduction } from '../../Components/Production/TableProduction/TableProduction';
import { AddProduction } from '../../Components/Production/Forms/AddProduction';
import dayjs from 'dayjs';

const { useToken } = theme;

const ProductionPage = () => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const { token } = useToken();

  const [production, setProduction] = useState<ProductionOverview | null>();
  const [productions, setProductions] = useState<ProductionOverview[]>([]);
  const [productionsTable, setProductionsTable] =
    useState<ProductionOverview[]>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModelEditMode, setModelEditMode] = useState(false);

  const childRef = useRef<any>();

  useEffect(() => {
    FetchData();
  }, [productions, productionsTable, production]);

  const FetchData = () => {
    serviceBundler.productionOverviewService
      .GetFetchStatus()
      .subscribe((value) => {
        if (!value) {
          return;
        }
        var fetechItems =
          serviceBundler.productionOverviewService.GetProductions();

        if (fetechItems !== undefined) {
          setProductionsTable(MapDates(fetechItems));
          setProductions(fetechItems);
        }
      });
  };

  const MapDates = (items: ProductionOverview[]) => {
    items.map((item) => {
      item.timeStampStart =
        item.timeStampStart != ''
          ? dayjs(item.timeStampStart).format('YYYY/MM/DD HH:mm:ss').toString()
          : '';
      item.timeStampStop =
        item.timeStampStop != null
          ? dayjs(item.timeStampStop).format('YYYY/MM/DD HH:mm:ss').toString()
          : undefined;
      return item;
    });
    return items;
  };

  const onSearch = (value: string) => {
    const node = childRef.current;
    node!.SetSearchText(value);
  };

  const onSearchType = (e: any) => {
    const node = childRef.current;
    node!.SetSearchText(e.target.value);
  };

  const NavigateToHome = () => {
    navigate('/');
  };

  const ClickRow = (item: ProductionOverview) => {
    showModalForEdit(item);
  };

  const SafeProduction = async (item: ProductionOverview) => {
    if (item.id !== undefined) {
      var items =
        await serviceBundler.productionOverviewService.UpdateProductionOverview(
          item,
        );
    } else {
      var items =
        await serviceBundler.productionOverviewService.AddProductionOverview(
          item,
        );
    }
    var newResult = [...items];
    setProductions(newResult);
    setProductionsTable(newResult);
    setIsModalOpen(false);
  };

  const handleCancelForm = () => {
    setIsModalOpen(false);
  };

  const RemoveProduction = async (props: ProductionOverview) => {
    setIsModalOpen(false);

    var result =
      await serviceBundler.productionOverviewService.RemoveProductionOverview(
        props,
      );
    var newResult = [...result];
    setProductions(newResult);
    setProductionsTable(newResult);
  };

  const showModalForEdit = (item: ProductionOverview) => {
    setProduction(item);
    setModelEditMode(true);
    setIsModalOpen(true);
  };

  const showModalForAdd = () => {
    setProduction(new ProductionOverview());
    setIsModalOpen(true);
    setModelEditMode(false);
  };

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const GetAvailableYears = () => {
    if (productions == undefined) {
      return [];
    }
    var allFoundYears = productions.map((x) => {
      return new Date(x.timeStampStart).getFullYear();
    });
    var allUniqueYears = Array.from(new Set(allFoundYears));
    return allUniqueYears.map((item) => {
      return {
        value: item,
        label: item,
      };
    });
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
      <Layout>
        <Header className={style.headerStyle}>
          <HeaderBar></HeaderBar>
        </Header>

        <Content
          style={{ backgroundColor: token?.colorPrimaryBg }}
          className={style.headerBlock}
        >
          <Col className={style.middleBlock}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                icon={<ArrowLeftOutlined />}
                onClick={NavigateToHome}
              ></Button>
              <div>
                <Select
                  placeholder={t('chooseyear')}
                  options={GetAvailableYears()}
                  style={{ width: 120, marginRight: 20 }}
                  onChange={handleChange}
                ></Select>
                <Search
                  placeholder={t('inputsearch').toString()}
                  allowClear
                  onChange={onSearchType}
                  onSearch={onSearch}
                  style={{ width: 200 }}
                />
              </div>
            </div>

            <Divider orientation="left">{t('production')}</Divider>

            <TableProduction
              ref={childRef}
              dataSource={productionsTable}
              clickRow={ClickRow}
            ></TableProduction>

            <AddProduction
              allData={
                productionsTable
                  ? productionsTable
                  : new Array<ProductionOverview>()
              }
              data={production ? production : new ProductionOverview()}
              open={isModalOpen}
              onCancel={handleCancelForm}
              onSafe={SafeProduction}
              onRemove={RemoveProduction}
              onEdit={isModelEditMode}
            ></AddProduction>

            <Button
              onClick={showModalForAdd}
              icon={<PlusCircleOutlined />}
              type="primary"
            >
              {t('addproduction')}
            </Button>
          </Col>
        </Content>
      </Layout>
    </Space>
  );
};

export default ProductionPage;
