import { Link as ReactLink, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../Providers/AuthProvider';
import {
  Col,
  Space,
  Layout,
  Button,
  Checkbox,
  Form,
  Input,
  Alert,
  Result,
  theme,
} from 'antd';
import { Content, Header } from 'antd/es/layout/layout';

import { KeyOutlined } from '@ant-design/icons';

import style from './LoginPage.module.css';
import { AuthResultFromServer } from '../../Providers/AuthResultFromServer';
import { useEffect, useState } from 'react';
import { authService, configThemeLight, serviceBundler } from '../../App';
import { UserModelSalt } from '../../Models/Auth/UserModelSalt';
import { useTranslation } from 'react-i18next';
import HeaderBar from '../../Components/Header/HeaderBar';
import Link from 'antd/es/typography/Link';

const { useToken } = theme;

interface AuthFormData {
  email: string;
  password: string;
}

const RenderLink = (bindedFunction: Function, textLink: string) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'start' }}>
      <Link
        style={{
          marginBottom: '10px',
          marginLeft: '130px',
          color: '#C1D202',
        }}
        onClick={() => bindedFunction()}
      >
        {textLink}
      </Link>
    </div>
  );
};

const RenderForgetPassword = (bindedFunction: Function, textLink: string) => {
  return (
    <Link
      style={{
        marginBottom: '20px',
        marginLeft: '80px',
        color: '#C1D202',
      }}
      onClick={() => bindedFunction()}
    >
      {textLink}
    </Link>
  );
};

const RenderAlert = (showAlert: boolean, alertMessage: string) => {
  if (!showAlert) {
    return;
  }
  return (
    <Alert
      className={style.renderPositionAlert}
      message={alertMessage}
      showIcon
      type="error"
    />
  );
};

const LoginPage = () => {
  const { i18n, t } = useTranslation();
  const { token } = useToken();

  const [form] = Form.useForm();

  const [alertLogin, showAlertLogin] = useState(false);
  const [passwordReset, showPasswordReset] = useState(false);

  const [loadingForResult, setLoadingForResult] = useState(false);

  let navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();

  let from = location.state?.from?.pathname || '/';

  useEffect(() => {
    if (authService.GetCurrentEmail() != null) {
      navigate('/profile');
    }
  }, [serviceBundler.themeService.IsDarkMode]);

  const handleLogin = (result: AuthResultFromServer) => {
    if (result.passwordCorrect) {
      navigate(from, { replace: true });
    } else {
      showAlertLogin(true);
    }
    setLoadingForResult(false);
  };

  const BackToHome = () => {
    navigate('/');
  };

  const handleSubmit = (values: AuthFormData) => {
    setLoadingForResult(true);
    var newAuthData = new UserModelSalt();
    newAuthData.email = values.email;
    newAuthData.password = values.password;

    auth.signin(newAuthData, handleLogin);
  };

  const onFinish = (values: AuthFormData) => {
    handleSubmit(values);
  };

  const Login = () => {
    showPasswordReset(false);
  };

  const ForgetPassword = () => {
    showPasswordReset(true);
    showAlertLogin(false);
  };

  const onFinishFailed = (errorInfo: any) => {};

  return (
    <Space direction="vertical" style={{ width: '100vw', height: '100vh' }}>
      <Layout>
        <Header className={style.headerStyle}>
          <HeaderBar></HeaderBar>
        </Header>

        <Content
          style={{ backgroundColor: token?.colorPrimaryBg }}
          className={style.globalBlock}
        >
          <div className={style.middleBlock}>
            <>
              {RenderAlert(alertLogin, 'Wachtwoord onjuist')}

              <Form
                name="basic"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                className={style.formWidth}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                onChange={() => {
                  showAlertLogin(false);
                }}
                form={form}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginLeft: '-130px',
                  }}
                >
                  {passwordReset && RenderLink(Login, 'Inloggen')}
                </div>

                {passwordReset && (
                  <Button
                    href="https://home.cloudkwekerijbloemendaal.com/login"
                    icon={<KeyOutlined />}
                    style={{ width: 300, marginTop: 30 }}
                    type="dashed"
                  >
                    {t('resetpassword')}
                  </Button>
                )}

                {!passwordReset && (
                  <Form.Item
                    label={t('emailadress')}
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Geef je gebruikersnaam',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                )}

                {!passwordReset &&
                  RenderForgetPassword(ForgetPassword, 'Wachtwoord vergeten?')}

                {!passwordReset && (
                  <>
                    <Form.Item
                      label={t('password')}
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: 'Geef je wachtwoord',
                        },
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>

                    <Form.Item
                      name="remember"
                      valuePropName="checked"
                      className={style.rememberText}
                    >
                      <Checkbox>{t('rememberme')}</Checkbox>
                    </Form.Item>

                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loadingForResult}
                    >
                      {t('login')}
                    </Button>
                  </>
                )}
              </Form>
            </>
          </div>
        </Content>
      </Layout>
    </Space>
  );
};

export default LoginPage;
