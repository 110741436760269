import { ResultFromToken } from "../Models/Auth/ResultFromToken"
import { UserModelSalt } from "../Models/Auth/UserModelSalt"
import { HttpHelper } from "./HttpHelper"

export abstract class AuthHelper{
    private static baseUrl = HttpHelper.GetUrlBaseApiHome() + "api/" + "token"

    public static async Login(authData: UserModelSalt): Promise<ResultFromToken>{
        var urlForRequest = this.baseUrl
        return (await HttpHelper.PostRequestForApi<ResultFromToken>(urlForRequest, authData))
    }
}