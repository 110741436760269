import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { NotificationDevice } from '../../../../Models/Devices/NotificationDevice';
import { SortableList } from '../../../SortableList';
import { serviceBundler, webSocketService } from '../../../../App';

import style from './CounterDeviceList.module.css';
import { useTranslation } from 'react-i18next';

import {
  LineChartOutlined,
  WifiOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import EditDeviceLay from '../../EditNotificationDeviceLay/EditNotificationDeviceLay';
import { MessageWebSocket } from '../../../../Models/WebSocket/MessageWebSocket';
import { MessageSock } from '../../../../Models/WebSocket/MessageSock';
import { WebSocketMessageType } from '../../../../Enums/WebSocketMessageType';
import { WsEspStatus } from '../../../../Models/WebSocket/WsEspStatus';
import { CounterDevice } from '../../../../Models/Devices/CounterDevice';
import EditCounterDeviceLay from '../../EditCounterDeviceLay/EditCounterDeviceLay';

const CounterDeviceList = forwardRef(
  (props: { isLoading: boolean; isEdit: Function }, ref) => {
    const { t, i18n } = useTranslation();

    const [editDevice, setEditDevice] = useState(false);
    const [externalEditDevice, setExternalEditDevice] = useState(false);

    const [loading, setLoading] = useState(true);
    const [connectionReady, setConnectionReady] = useState(false);

    const [counterDevices, setCounterDevices] = useState<CounterDevice[]>([]);
    const [counterDevice, setCounterDevice] = useState<CounterDevice>();
    const [loadingWifi, setLoadingWifi] = useState(true);

    useImperativeHandle(ref, () => ({
      AddDevice() {
        var newDevice = new CounterDevice();
        newDevice.id = '';
        setCounterDevice(newDevice);
        setEditDevice(true);
        serviceBundler.devicesService.AddCounterDevice(newDevice);
      },

      SetExternalEdit(value: boolean) {
        if (!editDevice) {
          setExternalEditDevice(value);
        }
      },
    }));

    useEffect(() => {
      if (loading) {
        FetchItems();
      }
      if (!loading && !connectionReady) {
        ConnectToWebsocket();
      }
    }, [counterDevices, loading, connectionReady]);

    useEffect(() => {
      if (editDevice) {
        props.isEdit(true);
      }
    }, [editDevice]);

    const FetchItems = async () => {
      serviceBundler.devicesService.GetFetchStatus().subscribe((value) => {
        if (!value) {
          return;
        }

        var fetchedDevices = serviceBundler.devicesService.GetCounterDevices();
        if (fetchedDevices !== undefined) {
          setCounterDevices(fetchedDevices);
        }

        SetWifiStatus();
        setLoading(false);
      });
    };

    const ConnectToWebsocket = () => {
      webSocketService.messages.subscribe((msg) => {
        const prodItem = Object.assign(new MessageWebSocket(), msg);

        HandleWebsocketMessages(prodItem, msg);
      });
    };

    const HandleWebsocketMessages = (
      prodItem: MessageWebSocket,
      msg: MessageSock,
    ) => {
      if (prodItem.Type == WebSocketMessageType.WsEspStatus) {
        var espState = Object.assign(new WsEspStatus(), msg);
        var foundedEsp = counterDevices?.find(
          (x) => x.moduleId == espState.ModuleId,
        );

        if (foundedEsp != undefined) {
          var item = foundedEsp;
          item.ipAddress = espState.CurrentIpAddress;
          item.online = true;

          var newDevices = [
            ...serviceBundler.devicesService.UpdateCounterDevice(item),
          ];

          setCounterDevices(newDevices);
        }
      }
    };

    const ChangeOrderDevices = (items: CounterDevice[]) => {
      var reorderedItems =
        serviceBundler.devicesService.ReplaceCounterDevices(items);
      setCounterDevices(reorderedItems);
    };

    const ShowLoadingForWifi = (isOnline: boolean): boolean => {
      if (loadingWifi && !isOnline) {
        return true;
      }
      return false;
    };

    const IsOfline = (isOnline: boolean): boolean => {
      if (!loadingWifi && !isOnline) {
        return true;
      }
      return false;
    };

    const SetWifiStatus = () => {
      if (!serviceBundler.devicesService.GetWifiStatus()) {
        setInterval(() => {
          setLoadingWifi(false);
          serviceBundler.devicesService.SetWifiStatus();
        }, 15000);
      } else {
        setLoadingWifi(false);
      }
    };

    const EditDevice = (item: CounterDevice) => {
      setEditDevice(true);
      setCounterDevice(item);
    };

    const RemoveDevice = (item: CounterDevice) => {
      var index = counterDevices.findIndex((x) => x.id == item.id);
      if (index >= 0) {
        counterDevices.splice(index, 1);
        setCounterDevices(counterDevices);
      }
      setEditDevice(false);
      props.isEdit(false);
    };

    const SafeDeviceWithoutClose = (item: CounterDevice) => {
      SafeDevice(item);
    };

    const SafeDeviceAndClose = (item: CounterDevice) => {
      SafeDevice(item);
      setEditDevice(false);
      props.isEdit(false);
    };

    const SafeDevice = (item: CounterDevice) => {
      setCounterDevice(item);
      var index = counterDevices.findIndex((x) => x.id == item.id);
      counterDevices[index] = item;
      setCounterDevices(counterDevices);
    };

    const CancelEditDevice = (item: CounterDevice) => {
      if (item.id == '') {
        var index = counterDevices.findIndex((x) => x.id == item.id);
        if (index >= 0) {
          counterDevices.splice(index, 1);
          setCounterDevices(counterDevices);
        }
      }

      setEditDevice(false);
      props.isEdit(false);
    };

    return (
      <>
        {!externalEditDevice && (
          <>
            {editDevice && (
              <EditCounterDeviceLay
                allData={counterDevices}
                data={counterDevice ? counterDevice : new CounterDevice()}
                onSafe={SafeDeviceAndClose}
                onSafeWithoutClose={SafeDeviceWithoutClose}
                onRemove={RemoveDevice}
                onCancel={CancelEditDevice}
              ></EditCounterDeviceLay>
            )}

            {!editDevice && (
              <SortableList
                items={counterDevices ? counterDevices : []}
                onChange={ChangeOrderDevices}
                renderItem={(item) => (
                  <SortableList.Item id={item.id}>
                    <div
                      className={style.stylingRow}
                      onClick={() => EditDevice(item)}
                    >
                      <div className={style.styleIcon}>
                        <LineChartOutlined style={{ fontSize: 20 }} />
                      </div>
                      <div className={style.styleName}>{item.moduleName}</div>
                      {item.online && (
                        <div className={style.styleWifi}>
                          <WifiOutlined style={{ fontSize: 20 }} />
                        </div>
                      )}
                      {IsOfline(item.online) && (
                        <>
                          <div className={style.styleWifi}>
                            <div className={style.diagonalLine}></div>
                            <WifiOutlined style={{ fontSize: 20 }} />
                          </div>
                        </>
                      )}
                      {ShowLoadingForWifi(item.online) && (
                        <div className={style.styleWifi}>
                          <LoadingOutlined style={{ fontSize: 20 }} />
                        </div>
                      )}
                    </div>
                    <SortableList.DragHandle />
                  </SortableList.Item>
                )}
              />
            )}
          </>
        )}
      </>
    );
  },
);

export default CounterDeviceList;
