import { ExternalShutdownSignal } from "./ExternalShutdownSignal"
import { ExternalPowerState } from "./ExternalPowerState"
import { ExternalPowerStateLinked } from "./ExternalPowerStateLinked"
import { TrayCounter } from "./TrayCounter"
import { ColorType } from "../../Enums/ColorType"

export class DbConnectedSensor{
  id!: string
  sensorId!: string
  sensorName!: string
  moduleName?: string
  showNotification: boolean = false;
  switchInputValue: boolean = false;
  visible: boolean = false;
  symbolForUrl!: string;
  reactionTimeTrue!: number;
  reactionTimeFalse!: number;
  valueTrue!: string;
  valueFalse!: string;

  valueTrueColor!: ColorType;
  valueFalseColor!: ColorType;

  trayCounter!: TrayCounter | null;
  externalPowerState!: ExternalPowerState | null;
  externalPowerStateLinked!: ExternalPowerStateLinked | null;
  externalShutdownSignal!: ExternalShutdownSignal | null;

  public constructor(init?:Partial<DbConnectedSensor>) {
    Object.assign(this, init);
  }
}


