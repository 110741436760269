import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { NotificationDevice } from '../../../../Models/Devices/NotificationDevice';
import { SortableList } from '../../../SortableList';
import { serviceBundler, webSocketService } from '../../../../App';

import style from './NotificationDeviceList.module.css';
import { useTranslation } from 'react-i18next';

import { BellOutlined, WifiOutlined, LoadingOutlined } from '@ant-design/icons';
import EditDeviceLay from '../../EditNotificationDeviceLay/EditNotificationDeviceLay';
import { MessageWebSocket } from '../../../../Models/WebSocket/MessageWebSocket';
import { MessageSock } from '../../../../Models/WebSocket/MessageSock';
import { WebSocketMessageType } from '../../../../Enums/WebSocketMessageType';
import { WsEspStatus } from '../../../../Models/WebSocket/WsEspStatus';
import { WsLogosStatus } from '../../../../Models/WebSocket/WsLogosStatus';

import ReactSVG from 'react-svg';
import SvgLogo from '../../../../Assets/wifi-high-svgrepo-com.svg';


const NotificationDeviceList = forwardRef(
  (props: { isLoading: boolean; isEdit: Function }, ref) => {
    const { t, i18n } = useTranslation();

    const [editDevice, setEditDevice] = useState(false);
    const [externalEditDevice, setExternalEditDevice] = useState(false);

    const [loading, setLoading] = useState(true);
    const [connectionReady, setConnectionReady] = useState(false);

    const [notificationDevices, setNotificationDevices] = useState<
      NotificationDevice[]
    >([]);
    const [notificationDevice, setNotificationDevice] =
      useState<NotificationDevice>();
    const [loadingWifi, setLoadingWifi] = useState(true);

    useImperativeHandle(ref, () => ({
      AddDevice() {
        var newDevice = new NotificationDevice();
        newDevice.id = '';
        setNotificationDevice(newDevice);
        setEditDevice(true);
        serviceBundler.devicesService.AddNotificationDevice(newDevice);
      },

      SetExternalEdit(value: boolean) {
        if (!editDevice) {
          setExternalEditDevice(value);
        }
      },
    }));

    useEffect(() => {
      if (loading) {
        FetchItems();
      }
      if (!loading && !connectionReady) {
        ConnectToWebsocket();
      }
    }, [notificationDevices, loading, connectionReady]);

    useEffect(() => {
      if (editDevice) {
        props.isEdit(true);
      }
    }, [editDevice]);

    const FetchItems = async () => {
      serviceBundler.devicesService.GetFetchStatus().subscribe((value) => {
        if (!value) {
          return;
        }

        var fetchedNotificationDevices =
          serviceBundler.devicesService.GetNotificationDevices();
        if (fetchedNotificationDevices !== undefined) {
          setNotificationDevices(fetchedNotificationDevices);
        }

        SetWifiStatus();
        setLoading(false);
      });
    };

    const ConnectToWebsocket = () => {
      webSocketService.messagesChannel.subscribe((msg) => {
        const prodItem = Object.assign(new MessageWebSocket(), msg);

        HandleWebsocketMessages(prodItem, msg);
      });
      setConnectionReady(true);
    };

    const HandleWebsocketMessages = (
      prodItem: MessageWebSocket,
      msg: MessageSock,
    ) => {
      if (prodItem.Type == WebSocketMessageType.WsEspStatus) {
        var espState = Object.assign(new WsEspStatus(), msg);
        var foundedEsp = notificationDevices?.find(
          (x) => x.moduleId == espState.ModuleId,
        );

        if (foundedEsp != undefined) {
          var item = foundedEsp;
          item.ipAddress = espState.CurrentIpAddress;
          item.online = true;

          var newDevices = [
            ...serviceBundler.devicesService.UpdateNotificationDevice(item),
          ];

          setNotificationDevices(newDevices);
        }
      }

      if (prodItem.Type == WebSocketMessageType.WsLogosStatus) {
        var logosState = Object.assign(new WsLogosStatus(), msg);

        logosState.LogosStatus.forEach((item) => {
          var foundedLogo = notificationDevices?.find(
            (x) => x.moduleId == item.ModuleId,
          );
          if (foundedLogo != undefined) {
            var logoItem = foundedLogo;
            logoItem.ipAddress = item.CurrentIpAddress;
            logoItem.online = true;
            setNotificationDevices(
              serviceBundler.devicesService.UpdateNotificationDevice(logoItem),
            );
          }
        });
      }
    };

    const ChangeOrderDevices = (items: NotificationDevice[]) => {
      var reorderedItems =
        serviceBundler.devicesService.ReplaceNotificationDevices(items);
      setNotificationDevices(reorderedItems);
    };

    const ShowLoadingForWifi = (isOnline: boolean): boolean => {
      if (loadingWifi && !isOnline) {
        return true;
      }
      return false;
    };

    const IsOfline = (isOnline: boolean): boolean => {
      if (!loadingWifi && !isOnline) {
        return true;
      }
      return false;
    };

    const SetWifiStatus = () => {
      if (!serviceBundler.devicesService.GetWifiStatus()) {
        setInterval(() => {
          setLoadingWifi(false);
          serviceBundler.devicesService.SetWifiStatus();
        }, 15000);
      } else {
        setLoadingWifi(false);
      }
    };

    const EditDevice = (item: NotificationDevice) => {
      setEditDevice(true);
      setNotificationDevice(item);
    };

    const RemoveDevice = (item: NotificationDevice) => {
      var index = notificationDevices.findIndex((x) => x.id == item.id);
      if (index >= 0) {
        notificationDevices.splice(index, 1);
        setNotificationDevices(notificationDevices);
      }
      setEditDevice(false);
      props.isEdit(false);
    };

    const SafeDeviceWithoutClose = (item: NotificationDevice) => {
      SafeDevice(item);
    };

    const SafeDeviceAndClose = (item: NotificationDevice) => {
      SafeDevice(item);
      setEditDevice(false);
      props.isEdit(false);
    };

    const SafeDevice = (item: NotificationDevice) => {
      setNotificationDevice(item);
      var index = notificationDevices.findIndex((x) => x.id == item.id);
      notificationDevices[index] = item;
      setNotificationDevices(notificationDevices);
    };

    const CancelEditDevice = (item: NotificationDevice) => {
      if (item.id == '') {
        var index = notificationDevices.findIndex((x) => x.id == item.id);
        if (index >= 0) {
          notificationDevices.splice(index, 1);
          setNotificationDevices(notificationDevices);
        }
      }

      setEditDevice(false);
      props.isEdit(false);
    };

    return (
      <>
        {!externalEditDevice && (
          <>
            {editDevice && (
              <EditDeviceLay
                allData={notificationDevices}
                data={
                  notificationDevice
                    ? notificationDevice
                    : new NotificationDevice()
                }
                onSafe={SafeDeviceAndClose}
                onSafeWithoutClose={SafeDeviceWithoutClose}
                onRemove={RemoveDevice}
                onCancel={CancelEditDevice}
              ></EditDeviceLay>
            )}

            {!editDevice && (
              <SortableList
                items={notificationDevices ? notificationDevices : []}
                onChange={ChangeOrderDevices}
                renderItem={(item) => (
                  <SortableList.Item id={item.id}>
                    <div
                      className={style.stylingRow}
                      onClick={() => EditDevice(item)}
                    >
                      <div className={style.styleIcon}>
                        <BellOutlined style={{ fontSize: 20 }} />
                      </div>
                      <div className={style.styleName}>{item.moduleName}</div>
                      {item.online && (
                        <div className={style.styleWifi}>
                          <WifiOutlined style={{ fontSize: 20 }} />
                        </div>
                      )}
                      {IsOfline(item.online) && (
                        <>
                          <div className={style.styleWifi}>
                            <div className={style.diagonalLine}></div>
                            <WifiOutlined style={{ fontSize: 20 }} />
                          </div>
                        </>
                      )}
                      {ShowLoadingForWifi(item.online) && (
                        <div className={style.styleWifi}>
                          <LoadingOutlined style={{ fontSize: 20 }} />
                        </div>
                      )}
                    </div>
                    <SortableList.DragHandle />
                  </SortableList.Item>
                )}
              />
            )}
          </>
        )}
      </>
    );
  },
);

export default NotificationDeviceList;
