import { authService } from "../App";
import { ControlDevice } from "../Models/Devices/ControlDevice";
import { HttpHelper } from "./HttpHelper";

export abstract class ControlDevicesHelper{
    private static _baseUrl = HttpHelper.GetUrlBaseApi() + "api/" + "ControlDevice";
    public static get baseUrl() {
        return ControlDevicesHelper._baseUrl;
    }
    public static set baseUrl(value) {
        ControlDevicesHelper._baseUrl = value;
    }

    public static async GetDevices(){
        var urlForRequest = this.baseUrl
        return (await HttpHelper.GetRequestForApi<ControlDevice[]>(urlForRequest))
    }

    public static async ReplaceDevices(devices: ControlDevice[]){
        var token = authService.GetToken();
        var urlForRequest = this.baseUrl + "/" + "ReplaceAll";
        return (await HttpHelper.PostRequestForApi<ControlDevice>(urlForRequest, devices));
    }

    public static async UpdateDevice(device: ControlDevice){
        var urlForRequest = this.baseUrl + "/" + device.id
        return (await HttpHelper.UpdateRequestForApi<ControlDevice>(urlForRequest, device))
    }

    public static async AddDevice(device: ControlDevice){
        var urlForRequest = this.baseUrl
        return (await HttpHelper.PostRequestForApi<ControlDevice>(urlForRequest, device))
    }

    public static async DeleteDevice(device: ControlDevice){
        var urlForRequest = this.baseUrl + "/" + device.id
        return (await HttpHelper.DeleteRequestForApi<ControlDevice>(urlForRequest, device))
    }
}