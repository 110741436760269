import { BehaviorSubject } from 'rxjs';
import { NotificationModule } from '../Models/Notifications/NotificationMachine';
import { WebSocketMessageType } from '../Enums/WebSocketMessageType';
import { WsPotlijnNotificationItems } from '../Models/WebSocket/WsPotlijnNotificationItems';
import { WsPotlijnNotificationItem } from '../Models/WebSocket/WsPotlijnNotificationItem';
import { MessageWebSocket } from '../Models/WebSocket/MessageWebSocket';
import { serviceBundler, webSocketService } from '../App';
import { IMessageSock } from '../Models/WebSocket/IMessageSock';
import { MessageSock } from '../Models/WebSocket/MessageSock';
import { ColorType } from '../Enums/ColorType';
import { ColorHelper } from '../Models/Devices/ColorOptions';
import { LocalHelper } from '../Helpers/LocalHelper';
import { HttpHelper } from '../Helpers/HttpHelper';
import { SettingsWebportal } from '../Models/Settings/SettingsWebportal';

export class NotificationService {
  public notificationsChannel: BehaviorSubject<NotificationModule[] | null>;
  public notificationsToKillChannel: BehaviorSubject<
    NotificationModule[] | null
  >;

  public notificationsProcessed: Array<NotificationModule> =
    new Array<NotificationModule>();
  public notificationsToKill: Array<NotificationModule> =
    new Array<NotificationModule>();

  private lastTimePlayed = Date.now();
  private soundPlaying = false;
  private soundPlayed = false;
  private allowPlaying: boolean = true;
  private timeBeforeRemove = 3;

  constructor() {
    this.notificationsChannel = new BehaviorSubject<
      NotificationModule[] | null
    >(null);

    this.notificationsToKillChannel = new BehaviorSubject<
      NotificationModule[] | null
    >(null);

    webSocketService.messagesChannel.subscribe((value) => {
      this.HandleWebsockets(value);
    });

    let timerId = setInterval(() => this._removeDoneNotifications(), 1000);
  }

  private HandleWebsockets(msg: IMessageSock) {
    const prodItem = Object.assign(new MessageWebSocket(), msg);

    if (prodItem.Type == WebSocketMessageType.PotlijnNotificationItems) {
      var itemAdded = false;

      var notifications: WsPotlijnNotificationItems = Object.assign(
        new WsPotlijnNotificationItems(),
        msg,
      );

      var newList: NotificationModule[] = [];
      notifications.ListItems.forEach((element) => {
        var res = this.AddNotification(element);
        if (element.SensorValue) {
          res.fixed = true;
          res.color = this.GetNameFromColor(element.ValueTrueColor);
        }

        var foundIndex = this.notificationsProcessed.findIndex(
          (x) => x.sensorId === element.SensorId,
        );
        if (foundIndex >= 0) {
          this.notificationsProcessed[foundIndex] = res;
          newList.push(res);
        } else {
          if (!res.fixed) {
            itemAdded = true;
            this.notificationsProcessed.push(res);
            newList.push(res);
          }
        }
      });

      this.notificationsChannel.next(newList);

      if (itemAdded) {
        if (!this.soundPlaying) {
          this.PlayAudio();
        }
      }
    }
  }

  private GetNameFromColor(type: ColorType): string {
    var colors = ColorHelper.GetColors();
    var rightColor: string = '';

    colors.forEach((x) => {
      if (x.KeyName == type) {
        rightColor = x.ValueKey;
        return;
      }
    });
    return rightColor;
  }

  private AddTestNotification(SensorId: string) {
    return new NotificationModule(
      'exclamation-triangle',
      SensorId,
      'Grondbunker',
      false,
      'Welkommmm',
      'https://storage.cloudkwekerijbloemendaal.com/Images/ground.png',
      'DarkRed',
      'error',
    );
  }

  private AddNotification(notification: WsPotlijnNotificationItem) {
    return new NotificationModule(
      'exclamation-triangle',
      notification.SensorId,
      notification.ModuleName,
      notification.SensorValue,
      notification.InformationFalse,
      notification.SymbolForUrl,
      this.GetNameFromColor(notification.ValueFalseColor),
      'error',
    );
  }

  public AllowPlayAudio(value: boolean) {
    this.allowPlaying = value;
  }

  public PlayAudioIsAllowed() {
    return this.allowPlaying;
  }

  private PlayAudio() {
    if (Date.now() + 50000 > this.lastTimePlayed) {
      this.lastTimePlayed = Date.now();
      if (this.allowPlaying) {
        var audio = new Audio(
          HttpHelper.GetUrlStorageBaseApi() +
            'Sounds/' +
            serviceBundler.settingsService.GetSetting().currentSoundTrack,
        );
        audio.play();
      }
      this.soundPlayed = true;
    } else {
      return;
    }
  }

  private _removeDoneNotifications() {
    if (serviceBundler.settingsService.GetSetting() != undefined) {
      this.timeBeforeRemove =
        serviceBundler.settingsService.GetSetting().timeForRemoveNotification;
    }

    var notiToKill: NotificationModule[] = [];
    this.notificationsProcessed.forEach((item, index) => {
      if (
        new Date(item.timeStamp).getTime() + this.timeBeforeRemove <
        new Date().getTime() / 1000
      ) {
        if (item.fixed) {
          notiToKill.push(item);
          this.notificationsProcessed.splice(index, 1);
        }
      }
    });

    if (notiToKill.length != 0) {
      this.notificationsToKillChannel.next(notiToKill);
    }
  }
}
