import { Button, List, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { SoundIdName } from '../../../Models/Settings/SoundIdName';

const CustomPopOver = (props: {
  data: SoundIdName[];
  removeItem: Function;
}) => {
  const RemoveSound = (item: string) => {
    props.removeItem(item);
  };

  return (
    <List
      bordered
      dataSource={props.data}
      renderItem={(item: SoundIdName) => (
        <List.Item>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              justifyItems: 'center',
            }}
          >
            <div>{item.name}</div>
            <div style={{ marginLeft: 10 }}>
              <Button
                onClick={() => RemoveSound(item.id)}
                icon={<DeleteOutlined />}
              ></Button>
            </div>
          </div>
        </List.Item>
      )}
    />
  );
};

export default CustomPopOver;
