import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { SortableList } from '../../../SortableList';
import { serviceBundler, webSocketService } from '../../../../App';

import style from './ContolDeviceList.module.css';
import { useTranslation } from 'react-i18next';

import {
  FieldTimeOutlined,
  WifiOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { MessageWebSocket } from '../../../../Models/WebSocket/MessageWebSocket';
import { MessageSock } from '../../../../Models/WebSocket/MessageSock';
import { WebSocketMessageType } from '../../../../Enums/WebSocketMessageType';
import { WsEspStatus } from '../../../../Models/WebSocket/WsEspStatus';
import { ControlDevice } from '../../../../Models/Devices/ControlDevice';
import EditControlDeviceLay from '../../EditControlDeviceLay/EditControlDeviceLay';

const ContolDeviceList = forwardRef(
  (props: { isLoading: boolean; isEdit: Function }, ref) => {
    const { t, i18n } = useTranslation();

    const [editDevice, setEditDevice] = useState(false);
    const [externalEditDevice, setExternalEditDevice] = useState(false);

    const [loading, setLoading] = useState(true);
    const [connectionReady, setConnectionReady] = useState(false);

    const [devices, setDevices] = useState<ControlDevice[]>([]);
    const [device, setDevice] = useState<ControlDevice>();
    const [loadingWifi, setLoadingWifi] = useState(true);

    useImperativeHandle(ref, () => ({
      AddDevice() {
        var newDevice = new ControlDevice();
        newDevice.id = '';
        setDevice(newDevice);
        setEditDevice(true);
        serviceBundler.devicesService.AddControlDevice(newDevice);
      },

      SetExternalEdit(value: boolean) {
        if (!editDevice) {
          setExternalEditDevice(value);
        }
      },
    }));

    useEffect(() => {
      if (loading) {
        FetchItems();
      }
      if (!loading && !connectionReady) {
        ConnectToWebsocket();
      }
    }, [devices, loading, connectionReady]);

    useEffect(() => {
      if (editDevice) {
        props.isEdit(true);
      }
    }, [editDevice]);

    const FetchItems = async () => {
      serviceBundler.devicesService.GetFetchStatus().subscribe((value) => {
        if (!value) {
          return;
        }

        var fetchedDevices = serviceBundler.devicesService.GetControlDevices();
        if (fetchedDevices !== undefined) {
          setDevices(fetchedDevices);
        }

        SetWifiStatus();
        setLoading(false);
      });
    };

    const ConnectToWebsocket = () => {
      webSocketService.messagesChannel.subscribe((msg) => {
        const prodItem = Object.assign(new MessageWebSocket(), msg);

        HandleWebsocketMessages(prodItem, msg);
      });
      setConnectionReady(true);
    };

    const HandleWebsocketMessages = (
      prodItem: MessageWebSocket,
      msg: MessageSock,
    ) => {
      if (prodItem.Type == WebSocketMessageType.WsEspStatus) {
        var espState = Object.assign(new WsEspStatus(), msg);
        var foundedEsp = devices?.find((x) => x.moduleId == espState.ModuleId);

        if (foundedEsp != undefined) {
          var item = foundedEsp;
          item.ipAddress = espState.CurrentIpAddress;
          item.online = true;

          var newDevices = [
            ...serviceBundler.devicesService.UpdateControlDevice(item),
          ];

          setDevices(newDevices);
        }
      }
    };

    const ChangeOrderDevices = (items: ControlDevice[]) => {
      var reorderedItems =
        serviceBundler.devicesService.ReplaceControlDevices(items);
      setDevices(reorderedItems);
    };

    const ShowLoadingForWifi = (isOnline: boolean): boolean => {
      if (loadingWifi && !isOnline) {
        return true;
      }
      return false;
    };

    const IsOfline = (isOnline: boolean): boolean => {
      if (!loadingWifi && !isOnline) {
        return true;
      }
      return false;
    };

    const SetWifiStatus = () => {
      if (!serviceBundler.devicesService.GetWifiStatus()) {
        setInterval(() => {
          setLoadingWifi(false);
          serviceBundler.devicesService.SetWifiStatus();
        }, 15000);
      } else {
        setLoadingWifi(false);
      }
    };

    const EditDevice = (item: ControlDevice) => {
      setEditDevice(true);
      setDevice(item);
    };

    const RemoveDevice = (item: ControlDevice) => {
      var index = devices.findIndex((x) => x.id == item.id);
      if (index >= 0) {
        devices.splice(index, 1);
        setDevices(devices);
      }
      setEditDevice(false);
      props.isEdit(false);
    };

    const SafeDeviceWithoutClose = (item: ControlDevice) => {
      SafeDevice(item);
    };

    const SafeDeviceAndClose = (item: ControlDevice) => {
      SafeDevice(item);
      setEditDevice(false);
      props.isEdit(false);
    };

    const SafeDevice = (item: ControlDevice) => {
      setDevice(item);
      var index = devices.findIndex((x) => x.id == item.id);
      devices[index] = item;
      setDevices(devices);
    };

    const CancelEditDevice = (item: ControlDevice) => {
      if (item.id == '') {
        var index = devices.findIndex((x) => x.id == item.id);
        if (index >= 0) {
          devices.splice(index, 1);
          setDevices(devices);
        }
      }

      setEditDevice(false);
      props.isEdit(false);
    };

    return (
      <>
        {!externalEditDevice && (
          <>
            {editDevice && (
              <EditControlDeviceLay
                allData={devices}
                data={
                  device
                    ? device
                    : new ControlDevice()
                }
                onSafe={SafeDeviceAndClose}
                onSafeWithoutClose={SafeDeviceWithoutClose}
                onRemove={RemoveDevice}
                onCancel={CancelEditDevice}
              ></EditControlDeviceLay>
            )}

            {!editDevice && (
              <SortableList
                items={devices ? devices : []}
                onChange={ChangeOrderDevices}
                renderItem={(item) => (
                  <SortableList.Item id={item.id}>
                    <div
                      className={style.stylingRow}
                      onClick={() => EditDevice(item)}
                    >
                      <div className={style.styleIcon}>
                        <FieldTimeOutlined style={{ fontSize: 20 }} />
                      </div>
                      <div className={style.styleName}>{item.moduleName}</div>
                      {item.online && (
                        <div className={style.styleWifi}>
                          <WifiOutlined style={{ fontSize: 20 }} />
                        </div>
                      )}
                      {IsOfline(item.online) && (
                        <>
                          <div className={style.styleWifi}>
                            <div className={style.diagonalLine}></div>
                            <WifiOutlined style={{ fontSize: 20 }} />
                          </div>
                        </>
                      )}
                      {ShowLoadingForWifi(item.online) && (
                        <div className={style.styleWifi}>
                          <LoadingOutlined style={{ fontSize: 20 }} />
                        </div>
                      )}
                    </div>
                    <SortableList.DragHandle />
                  </SortableList.Item>
                )}
              />
            )}
          </>
        )}
      </>
    );
  },
);

export default ContolDeviceList;
